import axios from 'axios';

// Create an Axios instance
const api = axios.create({
  baseURL: 'https://bo.atchoum.lebackyard.ovh/api/', // Replace with your API URL

});

// Request Interceptor
api.interceptors.request.use(
  (config) => {
    // Add Authorization token if available
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    // console.log('Request:', config);
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response Interceptor
api.interceptors.response.use(
  (response) => {
    //console.log('Response:', response);
    return response;
  },
  (error) => {
    if (error.response) {
      // Handle specific HTTP status codes
      if (error.response.status === 401) {
       // console.log('Unauthorized! Redirecting to login...');
        // window.location.href = '/login'; // Redirect user to login page
      } else if (error.response.status === 403) {
        console.log('Forbidden! You do not have permission.');
      }
    } else if (error.request) {
      console.log('No response from server.');
    } else {
      console.log('Request error:', error.message);
    }
    return Promise.reject(error);
  }
);

export default api;
