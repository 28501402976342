import { useLocation, useNavigate } from "react-router-dom";
import ReturnIcon from "../../assets/images/return.svg";

import "../../assets/styles/covoiturage.scss";
import Alert from "../../assets/images/danger.svg";
import { useEffect, useState } from "react";
import Autocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { FormCheck } from "react-bootstrap";
import CalendarAtchoum from "../../components/calendar";
import { isDisabled } from "@testing-library/user-event/dist/utils";
import { toast } from "react-toastify";
import { HelmetProvider ,Helmet} from "react-helmet-async";

const DetailsTrajet = () => {
  const navigate = useNavigate();
  const location=useLocation()
  const cible=location?.state;
  // console.log({cible})
  const [isGMapsLoaded, setIsGMapsLoaded] = useState(false);
  const [depart, setDepart] = useState("");
  const [destination, setDestination] = useState("");
  const [departLatLng, setDepartLatLng] = useState(null);
  const [destinationLatLng, setDestinationLatLng] = useState(null);
  const [error, setError] = useState("");
  const [errorDestination, setErrorDestination] = useState("");
  const [regulier, setRegulier] = useState(false);
  const [seats, setSeats] = useState(1);
  const [modalCalendar, setModalCalendar] = useState(false);
  const [confirmationDetails, setConfirmationDetails] = useState({}); // Control switch state
  const [departDateTimeValue, setDepartDateTimeValue] = useState("");
  const [commune, setCommune] = useState({dept:"",dest:""});
  const [postalCode, setPostalCode] = useState({dept:"",dest:""});
  const [weekDays, setWeekDays] = useState([
    {
      joursTrajet: "Lundi",
      timeAller: "",
      timeRetour: "",
      statu: false,
      activeAller: false,
      activeRetour: false,
    },
    {
      joursTrajet: "Mardi",
      timeAller: "",
      timeRetour: "",
      statu: false,
      activeAller: false,
      activeRetour: false,
    },
    {
      joursTrajet: "Mercredi",
      timeAller: "",
      timeRetour: "",
      statu: false,
      activeAller: false,
      activeRetour: false,
    },
    {
      joursTrajet: "Jeudi",
      timeAller: "",
      timeRetour: "",
      statu: false,
      activeAller: false,
      activeRetour: false,
    },
    {
      joursTrajet: "Vendredi",
      timeAller: "",
      timeRetour: "",
      statu: false,
      activeAller: false,
      activeRetour: false,
    },
    {
      joursTrajet: "Samedi",
      timeAller: "",
      timeRetour: "",
      statu: false,
      activeAller: false,
      activeRetour: false,
    },
    {
      joursTrajet: "Dimanche",
      timeAller: "",
      timeRetour: "",
      statu: false,
      activeAller: false,
      activeRetour: false,
    },
  ]);
  const formatDate = (dateString) => {
    // Split the input string into date and time parts
    // console.log(dateString)
    const [datePart, timePart] = dateString.split(' ');
  
    // Split the date part into day, month, and year
    const [year,month, day  ] = datePart.split('-').map(Number);
  
    // Create a Date object using the extracted year, month, and day
    const date = new Date(year, month - 1, day); // month is 0-indexed in JS
  
    // Define arrays for day names and month names in French
    const daysOfWeek = [
      'Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'
    ];
    const monthNames = [
      'janvier', 'février', 'mars', 'avril', 'mai', 'juin', 
      'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre'
    ];
  
    // Get the day of the week and the month name
    const dayName = daysOfWeek[date.getDay()]; // getDay() gives the day of the week (0-indexed)
    const monthName = monthNames[month - 1]; // month is 1-indexed
  
    // Split and format the time part (24-hour format)
    const [hours, minutes] = timePart.split(':');
  
    // Construct the final formatted string
    const formattedDate = `${dayName} ${day.toString().padStart(2, '0')} ${monthName} à ${hours}h${minutes}`;
    // console.log(formattedDate)
  
    setDepartDateTimeValue(formattedDate) ;
  };
  const [isDisabled, setIsDisabled] = useState(true);

useEffect(() => {
 
  const checkIsDisabled = () => {
    // console.log(departLatLng , destinationLatLng)
      return (
        !departLatLng ||
        !destinationLatLng ||
        !seats || seats==0 ||
        (!regulier && !departDateTimeValue) || // if not regulier, departDateTimeValue should not be empty
        (regulier && weekDays.filter((day) => day.statu).length === 0) // if regulier, at least one day should be selected
      );
  };

  setIsDisabled(checkIsDisabled);
}, [depart, destination,departLatLng,destinationLatLng,seats, departDateTimeValue,regulier,weekDays]);
  
  
  const saveToLocalStorage = () => {
    // Get only active days
    // console.log({weekDays})
    localStorage.setItem('weekDays', JSON.stringify(weekDays));
    const transformedWeekDays = weekDays
    .filter((day) => (day.statu && (day.timeAller!==""|| day.timeRetour!=="")))
    .flatMap((day) => {
      const entries = [];
    
    if (day.activeAller) {
      entries.push({
        joursTrajet: day.joursTrajet,
        typeTrajet: 1, // Aller
        timeTrajet: day.timeAller,
        status: day.statu,
      });
    }

    if (day.activeRetour) {
      entries.push({
        joursTrajet: day.joursTrajet,
        typeTrajet: 0, // Retour
        timeTrajet: day.timeRetour,
        status: day.statu,
      });
    }

    return entries; // Only return the filled entries
  });
    if(transformedWeekDays.length===0 && regulier) {
      toast.error('Horaires manquants', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
       // theme: 'light', // Choose theme (light/dark)
        className: 'custom-toast-error'

      });
      return;
    }
    // console.log({transformedWeekDays});
    // Prepare the data to save
    const dataToSave = {
      confirmationDetails,
      depart,
      departLatLng,
      destination,
      destinationLatLng,
      regulier,
      seats,
      commune,
      postalCode,
      transformedWeekDays, // Save only active days
    };
    // console.log("dataToSave", dataToSave);
    // Save to localStorage
    localStorage.setItem("detailsTrajet", JSON.stringify(dataToSave));
    // console.log("Navigating...");
    navigate("/information-complementaire" ,{state:cible});
    const adrHistoric = [
      {
        address: depart,
        lng: departLatLng?.lng,
        lat: departLatLng?.lat,
      },
      {
        address: destination,
        lng: destinationLatLng?.lng,
        lat: destinationLatLng?.lat,
      },
    ];
    
    // Save to address history
    const addressHistoryKey = "addressHistory";
    const existingHistory = JSON.parse(localStorage.getItem(addressHistoryKey)) || [];
    
    // Flatten the array to avoid nesting and combine it with the existing history
    const updatedHistory = [...adrHistoric, ...existingHistory].slice(0, 5); // Keep only the last 5 entries
    
    // Save the updated history back to localStorage
    localStorage.setItem(addressHistoryKey, JSON.stringify(updatedHistory));
    
  };
  const handleChangeAddress = (address, type) => {
    if (type === "destination") {
      setDestination(address);
      setDestinationLatLng(null)
      setErrorDestination("");
    } else {
      setDepart(address);
      setDepartLatLng(null)
      setError("");
    }
  };
 
  const handleSelect = async (address, type) => {
    try {
      const results = await geocodeByAddress(address);
      const latLng = await getLatLng(results[0]);
      // console.log(results)
     
        const addressComponents = results[0].address_components;

       // Find the component with 'locality' type for city/commune
       const cityComponent = addressComponents.find(component => 
        component.types.includes('locality')
      );
      const postalCode = addressComponents.find(component =>
        component.types.includes('postal_code')
      );
      const streetComponent = addressComponents.find(component =>
        component.types.includes('route')
      );
      // Set the commune (city)
      // console.log(cityComponent)
      if (cityComponent  ) {
        if (type === "destination") {
          if (
            address === depart || 
            (departLatLng && latLng.lat === departLatLng.lat && latLng.lng === departLatLng.lng)
          ) {
            alert("La destination doit être différente du départ.");
            return;
          }
          setDestination(address);
          setDestinationLatLng(latLng);
        } else {
          if (
            address === destination || 
            (destinationLatLng && latLng.lat === destinationLatLng.lat && latLng.lng === destinationLatLng.lng)
          ) {
            alert("Le départ doit être différent de la destination.");
            return;
          }
          setDepart(address);
          setDepartLatLng(latLng);
        }
        const cityName = cityComponent.long_name;
        const code = postalCode.long_name;
        setPostalCode((prevCommune) => {
        if (type === "destination") {
          return { ...prevCommune, dest: code }; // Set destination commune
        } else if (type === "depart") {
          return { ...prevCommune, dept: code }; // Set departure commune
        }
        return prevCommune;
      });
        setCommune((prevCommune) => {
          if (type === "destination") {
            return { ...prevCommune, dest: cityName }; // Set destination commune
          } else if (type === "depart") {
            return { ...prevCommune, dept: cityName }; // Set departure commune
          }
          return prevCommune;
        });
      } else  {
       // console.error('Commune not found in the selected address');
       toast.error("Veuillez fournir l'adresse complète", {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          draggable: true,
          progress: undefined,
         // theme: 'light', // Choose theme (light/dark)
          className: 'custom-toast-error'
  
        });
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const searchOptions = {
    componentRestrictions: { country: ["fr"] },
  types: ["geocode","establishment"],
  }
  const handleSwitchChange = (type) => {
    setRegulier(!regulier);
    setDepartDateTimeValue(null)
  };
  const handleDayToggle = (index) => {
    setWeekDays((prev) =>
      prev.map((day, i) => (i === index ? { ...day, statu: !day.statu } : day))
    );
  };
  const handleAllerToggle = (index) => {
    setWeekDays((prev) =>
      prev.map((day, i) =>
        i === index ? { ...day, activeAller: !day.activeAller } : day
      )
    );
  };

  const handleRetourToggle = (index) => {
    setWeekDays((prev) =>
      prev.map((day, i) =>
        i === index ? { ...day, activeRetour: !day.activeRetour } : day
      )
    );
    // console.log(weekDays)
  };
 

 

  const handleTimeChange = (index, type, value) => {
    setWeekDays((prev) =>
      prev.map((day, i) => (i === index ? { ...day, [type]: value } : day))
    );
    // console.log(weekDays);
  };

  const calculateMinTime = (timeAller) => {
  let [hours, minutes] = timeAller.split(":");
  let date = new Date();
  date.setHours(parseInt(hours), parseInt(minutes));

  // Add 30 minutes
  date.setMinutes(date.getMinutes() + 30);

  // Format time back to HH:MM
  let newHours = date.getHours().toString().padStart(2, '0');
  let newMinutes = date.getMinutes().toString().padStart(2, '0');

  return `${newHours}:${newMinutes}`;
};
  useEffect(() => {
   // setSavedData(JSON.parse(localStorage.getItem('detailsTrajet')) || {})
    const detailsTrajet=JSON.parse(localStorage.getItem('detailsTrajet'));
   const weekDaysFromStorage=JSON.parse(localStorage.getItem('weekDays'));
   weekDaysFromStorage && setWeekDays(weekDaysFromStorage)
    // console.log(detailsTrajet?.seats)
    detailsTrajet?.depart && setDepart(detailsTrajet?.depart);
    setDepartLatLng(detailsTrajet?.departLatLng)
    setPostalCode(detailsTrajet?.postalCode)
    setDestination(detailsTrajet?.destination)
    setDestinationLatLng(detailsTrajet?.destinationLatLng)
    setSeats(parseInt(detailsTrajet?.seats))
    detailsTrajet?.commune && setCommune(detailsTrajet?.commune)
    detailsTrajet?.confirmationDetails && setConfirmationDetails(detailsTrajet?.confirmationDetails)
    setRegulier(detailsTrajet?.regulier)
    // console.log('saved data',JSON.parse(localStorage.getItem('detailsTrajet')))
    if(window.google) setIsGMapsLoaded(true)
    if (!window.google) {
      const gmapScriptEl = document.createElement("script");
      gmapScriptEl.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places`;
      gmapScriptEl.async = true;
      gmapScriptEl.defer = true;
      gmapScriptEl.onload = () => setIsGMapsLoaded(true);
      document.body.appendChild(gmapScriptEl);
    } else {
      setIsGMapsLoaded(true);
    }

  }, []);
  useEffect(()=>{
    confirmationDetails?.depart?.date && formatDate(confirmationDetails?.depart.date)
  },[modalCalendar,confirmationDetails?.depart?.date])
  return (
    <>
    <HelmetProvider>
      <Helmet>
            <title>Page détails trajet </title>
            <meta name="description" content="page details trajet adresse et date" />
        </Helmet>
    </HelmetProvider>
    
      {modalCalendar && (
        <CalendarAtchoum
          open={modalCalendar}
          setOpen={setModalCalendar}
          home={false}
          type={'depart'}
          setConfirmationDetails={setConfirmationDetails}
          confirmationDetails={confirmationDetails}
        
        />
      )}
      
      <div className="bg-grey">
        <div className="stepDetails ">
          <div className="container-fluid-lg p-16">
            <h1 className="text-white  d-flex align-items-center">
              <button onClick={() => navigate(-1)} className="cta">
                <img src={ReturnIcon} className="mr-8" alt="return icon" />
              </button>
              Votre trajet
            </h1>
          </div>
        </div>
        <h2 className="px-16 m-0 bleuM500  col-lg-6 mx-auto ">
          Détail de votre trajet
        </h2>

        <div className="px-16 col-lg-6 mx-auto mt-8">
          {/* Autocomplete for Depart */}
          <div className="form-floating ">
            {isGMapsLoaded && (
              <Autocomplete
                value={depart}
                searchOptions={searchOptions}
                onChange={(addr) => handleChangeAddress(addr, "depart")}
                onSelect={(addr) => handleSelect(addr, "depart")}
              >
                {({
                  getInputProps,
                  suggestions,
                  getSuggestionItemProps,
                  loading,
                }) => (
                  <div className="floating-input-container">
                    <input
                      {...getInputProps({
                        placeholder: "",
                        className: `floating-input input-location radius-top ${
                          error ? "danger-input" : ""
                        }`,
                      })}
                      id="floatingAdr"
                    />
                    <label
                      htmlFor="floatingAdr"
                      className="floating-label home-label"
                    >
                      Adresse de départ
                    </label>
                    <div
                      className={
                        suggestions.length > 0 ? "container-autocomplete" : ""
                      }
                    >
                      {loading ? <div>Loading...</div> : null}
                      {suggestions.map((suggestion) => {
                        const style = {
                          backgroundColor: suggestion.active
                            ? "#41B6E6"
                            : "#fff",
                        };
                        return (
                          <div
                            className="mapItem"
                            {...getSuggestionItemProps(suggestion, { style })}
                          >
                            {suggestion.description}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </Autocomplete>
            )}
          </div>

          {/* Autocomplete for Destination */}
          <div className="form-floating">
            {isGMapsLoaded && (
              <Autocomplete
                value={destination}
                searchOptions={searchOptions}
                onChange={(addr) => handleChangeAddress(addr, "destination")}
                onSelect={(addr) => handleSelect(addr, "destination")}
              >
                {({
                  getInputProps,
                  suggestions,
                  getSuggestionItemProps,
                  loading,
                }) => (
                  <div className="floating-input-container mb-3">
                    <input
                      {...getInputProps({
                        placeholder: "",
                        className: `floating-input  radius-bottom ${
                          errorDestination ? "danger-input" : ""
                        }`,
                      })}
                      id="floatingDestination"
                    />
                    <label
                      htmlFor="floatingDestination"
                      className="floating-label home-label"
                    >
                      Adresse d’arrivée
                    </label>
                    <div
                      className={
                        suggestions.length > 0 ? "container-autocomplete" : ""
                      }
                    >
                      {loading ? <div>Loading...</div> : null}
                      {suggestions.map((suggestion) => {
                        const style = {
                          backgroundColor: suggestion.active
                            ? "#41B6E6"
                            : "#fff",
                        };
                        return (
                          <div
                            className="mapItem"
                            {...getSuggestionItemProps(suggestion, { style })}
                          >
                            {suggestion.description}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </Autocomplete>
            )}
          </div>

          <div className="text-danger-custom">{errorDestination}</div>
          <div className="text-danger-custom">{error}</div>
          {/* Number of Seats */}
          <div className="floating-input-container mt-8">
            <input
              type="number"
              name="seats"
              className="floating-input "
              placeholder=""
              value={seats}
              min={1}
              onChange={(e) => {
                setSeats(e.target.value);
              }}
            />
            <label htmlFor="seats" className="floating-label home-label">
              Nombre de places{" "}
            </label>
            
            {seats < 1 &&  <div className="error-message">
                      <img src={Alert} alt="Alert" />
                      Nombre de places doivent être au minimum 1.
                    </div>}
          </div>
          <div className="mt-24">
            <div className="col">
              <div className="custom-switch custom-switch-label-io d-flex align-items-center">
                <FormCheck
                  type="switch"
                  id="custom-switch-pmr"
                  className="form-switch mr-8"
                  checked={regulier}
                  onChange={() => handleSwitchChange()}
                />
                <label className="beluM500 exergue" htmlFor="custom-switch-pmr">
                  Mon trajet est régulier
                </label>
              </div>
            </div>
          </div>
          <div className="bg-white"></div>
          {regulier ? (
            <div className="bg-white p-16 mt-16">
              {weekDays.map((day, index) => (
                <div key={day.joursTrajet} className="day-section mb-3">
                  {/* Day Name and Toggle */}
                  <div className="d-flex justify-content-between align-items-center">
                    <label className="exergue bleuM500">{`Chaque ${day.joursTrajet}`}</label>
                    <FormCheck
                      type="switch"
                      className="form-switch"
                      checked={day.statu}
                      onChange={() => handleDayToggle(index)} // Toggle the active state
                    />
                  </div>

                  {/* Time Inputs with Toggles */}
                  {day.statu && (
                    <div className="bg-horaire mt-2">
                      {/* Aller (Departure Time) */}

                      <div className="d-flex border-b pb-8 justify-content-between align-items-center">
                        <label className="current-text bleuM500">Aller</label>

                        <div className="d-flex align-items-center">
                          <input
                            type="time"
                            className="input-time-atchoum floating-input  w-auto mr-8"
                            value={day.timeAller}
                            onChange={(e) =>
                              handleTimeChange(
                                index,
                                "timeAller",
                                e.target.value
                              )
                            }
                            disabled={!day.activeAller} // Enable/Disable based on Aller toggle
                          />
                          <FormCheck
                            type="switch"
                            className="form-switch"
                            checked={day.activeAller}
                            onChange={() => handleAllerToggle(index)} // Toggle for Aller
                          />
                        </div>
                      </div>

                      {/* Retour (Return Time) */}

                      <div className="d-flex justify-content-between align-items-center mt-8">
                        <label className="current-text bleuM500">Retour</label>

                        <div className="d-flex align-items-center">
                          <input
                            type="time"
                            className="input-time-atchoum floating-input  w-auto mr-8"
                            value={day.timeRetour}
                            min={calculateMinTime(day.timeAller)} // Calculate min time (aller + 30 minutes)
                            onInput={(e) => {
                              if (e.target.value < day.timeAller) {
                                e.target.setCustomValidity("Time must be greater than or equal to " + day.timeAller);
                              } else {
                                e.target.setCustomValidity(""); // Reset message if valid
                              }
                              handleTimeChange(index, "timeRetour", e.target.value);
                            }}
                            onChange={(e) => {
                              const selectedTime = e.target.value;
                              const minTime = calculateMinTime(day.timeAller); // Calculate the minimum allowed time (timeAller + 30 minutes)
                          
                              if (selectedTime < minTime) {
                                // If the selected time is less than the minimum allowed time, set it to the minimum time
                                handleTimeChange(index, "timeRetour", minTime);
                              } else {
                                // Otherwise, set the selected time
                                handleTimeChange(index, "timeRetour", selectedTime);
                              }
                            }}
                            disabled={!day.activeRetour} // Enable/Disable based on Retour toggle
                          />
                          <FormCheck
                            type="switch"
                            className="form-switch"
                            checked={day.activeRetour}
                            onChange={() => handleRetourToggle(index)} // Toggle for Retour
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          ) : (
            <div className="floating-input-container mt-16">
              <input
                type="text"
                name="departDateTime"
                onClick={() => {
                  setModalCalendar(!modalCalendar);
                }}
                value={departDateTimeValue}
                className="date-home  floating-input"
                placeholder=""
                readOnly
              />
              <label htmlFor="dateDepart" className="floating-label">
                Date et heure de départ
              </label>
            </div>
          )}
          <div className="h-xs-100"></div>
          <div className="bottom-white-block">
            <button
              type="submit"
              className="btn-primary-s p-16 w-sm-100"
              disabled={isDisabled}
              onClick={saveToLocalStorage}
            >
              Suivant
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
export default DetailsTrajet;
