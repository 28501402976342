import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'; // Import the default Skeleton styles

const Loader = ({ type }) => {
  const count = type === '2' ? 2 : type === '3' ? 3 : 1; 
 

  return (
    <div className="p-16">
       {Array.from({ length: count }).map((_, index) => (
      <div key={index} className="loader-skeleton">
        <Skeleton height={150} width="100%" style={{ marginBottom: '10px' }} />
        <Skeleton height={150} width="100%" style={{ marginBottom: '10px' }} />
        <Skeleton height={150} width="100%" style={{ marginBottom: '10px' }} />
        <Skeleton count={3} />
        <Skeleton height={20} width="80%" />
      </div>
    ))}
    </div>
   
  )
  ;
};

export default Loader;
