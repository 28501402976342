import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ReturnIcon from "../../assets/images/return.svg";
import CheckFill from "../../assets/images/checkfill.svg";
import "../../assets/styles/bulletin.scss";
import Services from "../../services/global.service";
import { toast } from "react-toastify";
import { HelmetProvider ,Helmet} from "react-helmet-async";

const Bulletin = () => {
  const navigate = useNavigate();

  // State to track if the checkbox is checked
  const [isChecked, setIsChecked] = useState(false);
  const [zoneTus, setZoneTus] = useState(false);

  // Handle checkbox toggle
  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };
  const getMyProfil = () => {
    const idUser = localStorage.getItem("userData");
    // console.log("userData", idUser);
    Services.getOne(idUser, "users")
      .then((res) => {
        const userData = res?.data?.data;

        userData?.adhesion_mobilite_village &&
          setIsChecked(userData?.adhesion_mobilite_village);
        userData?.zonetus && setZoneTus(userData?.zonetus);

        // console.log(userData);
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  useEffect(() => {
    getMyProfil();
  }, []);
  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
  
      // Handle the form submission logic here
      // console.log("Form submitted");
      const data = {
        adhesion_mobilite_village: isChecked,
        conducteur_solidaire: true,
      };
      // Navigate to the next page or take appropriate action
      Services.create(data, "update_user")
        .then((res) => {
          navigate("/a-propos-conducteur-solidaire");
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message, {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            draggable: true,
            progress: undefined,
            onClose:()=> navigate("/a-propos-conducteur-solidaire"),
           // theme: 'light', // Choose theme (light/dark)
            className: 'custom-toast-error'
    
          });
          // console.log(err);
        });
  
  };

  return (
    <>
    <HelmetProvider>
      <Helmet>
            <title>Adhésion à l'association</title>
            <meta name="description" content="bulletin d'adhésion à l'association  " />
        </Helmet>
    </HelmetProvider>
         
      <div className="bg-grey">
        <div className="stepOne ">
          <div className="container-fluid-lg p-16">
            <h1 className="text-white d-flex">
              <button onClick={() => navigate(-1)} className="cta">
                <img src={ReturnIcon} className="mr-8" alt="return icon" />
              </button>
              Devenir conducteur solidaire
            </h1>
          </div>
        </div>
        <div className="col-lg-6 mx-auto">
          <h2 className="p-16 bleuM500 container-fluid-lg">
            Bulletin d’adhésion à l’association
          </h2>
          <div className="px-16 container-fluid-lg ">
            {zoneTus && (
              <div className="solidaire">
                <p className="current-text bleuM500 text-start">
                  Votre commune relève de Transport d’Utilité Sociale. Si vous
                  souhaitez proposer un trajet, l’adhésion à notre association
                  Atchoum mobilité villages est alors obligatoire.
                </p>
              </div>
            )}
            <div className="why mt-24">
              <p className="exergue bleuM500">
                Pourquoi adhérer à l'association ?
              </p>
              <div className="mt-16">
                <p className="current-text p-0 m-0 bleuM500 align-items-center d-flex">
                  <img src={CheckFill} alt="check icon" className="mr-8" />
                  Adhésion gratuite
                </p>
                <p className="current-text p-0 mb-0 bleuM500 align-items-start d-flex mt-8">
                  <img src={CheckFill} alt="check icon" className="mr-8" />
                  Si vous le souhaitez, vous pouvez bénéficier de l'assurance 
                  de l'association moyennant une cotisation de 5€/an (optionnel), 
                  vous serez couvert tout risque 
                  pour tous les trajets enregistrés et réalisés avec Atchoum
                </p>
                <p className="current-text p-0 mb-0 bleuM500 align-items-start d-flex mt-8">
                  <img src={CheckFill} alt="check icon" className="mr-8" />
                  Pour créer une communauté entre les utilisateurs et recevoir
                  des invitations à des évènements ...
                </p>
                <div className="form-group text-start mt-8">
                  <label className="d-flex align-items-center current-text">
                    <input
                      type="checkbox"
                      name="accepteCguCgv"
                      id="accepteCguCgv"
                      checked={isChecked}
                      onChange={handleCheckboxChange} // Handle checkbox change
                    />
                    <span className="custom-checkbox"></span>
                    <span>
                      {" "}
                      Je souhaite adhérer
                      <a target="_blank"  href="https://bo.atchoum.lebackyard.ovh/Bulletin_adh%C3%A9sion_Atchoum-_004_-V-GILBERT-_1_.pdf" className="cta-bleu current-text">
                        {" "}
                        à l'association atchoum mobilité villages{" "}
                      </a>
                    </span>
                  </label>
                </div>
              </div>
              <div className="h-xs-100"></div>

              <div className="bottom-white-block">
                <button
                  type="submit"
                  onClick={handleSubmit} // Handle form submission
                  className="btn-primary-s p-16 w-sm-100"
                  disabled={!isChecked && zoneTus} // Disable the button if the checkbox is unchecked
                >
                  Suivant
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Bulletin;
