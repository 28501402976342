import { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import Services from "../services/global.service";
import Loader from "../components/loader";
import { useMyContext } from "../context";

export default function ProtectedRoutes({ role }) {
  const [isAuthenticated, setIsAuthenticated] = useState(null); // Tracks authentication state (null, true, false)
  const [isLoading, setIsLoading] = useState(true); // Loading state during async validation
  const { userData } = useMyContext();
  useEffect(() => {
    const auth = userData ? userData?.token : localStorage.getItem("token");
    // console.log({auth})
    if (auth) {
      // Validate token by calling the backend service
      Services.getAllWithToken("check-token",auth)
        .then((res) => {
          if (res.data.status === "success") {
            setIsAuthenticated(true); // Token is valid
          } else {
            localStorage.clear();
            setIsAuthenticated(false); // Token invalid or expired
          }
        })
        .catch(() => {
          localStorage.clear();
          setIsAuthenticated(false); // Error in token validation
        })
        .finally(() => {
          setIsLoading(false); // End loading state after validation
        });
    } else {
      setIsAuthenticated(false); // No token found
      setIsLoading(false); // End loading state
    }
  }, []);

  if (isLoading) {
    return <Loader type='3' />;; // Optionally show a loader while checking authentication
  }

  if (isAuthenticated) {
    return <Outlet />; // Allow access to protected routes
  } else {
    return <Navigate to="/login" />; // Redirect to login if not authenticated
  }
}
