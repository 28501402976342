import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Collapse from 'react-bootstrap/Collapse';
import ArrowUp from '../assets/images/arrow-up.svg';

const Faq = () => {
  const [open, setOpen] = useState(Array(16).fill(false)); // Initialisation pour 9 FAQ
  const [showAll, setShowAll] = useState(false); // État pour afficher toutes les questions

  const toggleCollapse = (index) => {
    setOpen((prevOpen) => {
      const newOpen = [...prevOpen];
      newOpen[index] = !newOpen[index]; // Toggle la question sélectionnée
      return newOpen;
    });
  };

  const Faqs = [
    { title: "C’est quoi Atchoum ?", content: "Atchoum est une solution de covoiturage et transport solidaire. Il s’agit d’une plateforme de mise en relation entre passagers et conducteurs." },
    { title: "En quoi se différencie Atchoum par rapport aux solutions existantes ?", content: "Atchoum est une véritable solution de mobilité rurale 2-en-1, sans commission. Atchoum dispose d’un site web de mise en relation, complété d’un centre d’appels téléphonique basé en France, et de tickets mobilité pour les personnes ne souhaitant ou ne pouvant pas payer en carte bancaire." },
    { title: "Pourquoi ce nom Atchoum ?", content: "Car « Atchoum réalise vos souhaits de mobilité »." },
    { title: "Besoin de me déplacer, comment faire une demande de trajet ?", content: "Soit appeler notre centre d’appel téléphonique, mais il faut que votre commune soit abonnée, soit faire sa demande par notre site internet www.atchoum.eu." },
    { title: "Combien coûte un trajet ?", content: "Le coût est toujours calculé automatiquement et avant le trajet. Il faut prévoir 0.32 € du km en transport solidaire et environ 0.11 € du km en covoiturage." },
    { title: "Comment payer ?", content: "Jamais de la main à la main, toujours en prépaiement au moment de sa demande de trajet par CB (carte bancaire) ou ticket mobilité." },
    { title: "À quoi servent les tickets mobilité ?", content: "Les tickets mobilité permettent de payer ses trajets sans avoir besoin d’une carte bancaire ni d’un smartphone." },
    { title: "Comment se procurer des tickets mobilité ?", content: "Auprès de votre commune ou communauté de communes." },
    { title: "Comment est indemnisé le conducteur ?", content: "Le conducteur est indemnisé après le trajet par virement bancaire, sans commission." },
    { title: "Comment est-on assuré ?", content: "Par l’assurance du conducteur ou par notre Association si le conducteur a adhéré à notre Association Atchoum Mobilité Village, pour la somme symbolique de 5€/an et en tous risques !" },
    { title: "Est-ce qu’il y a des horaires et des limitations de distance ?", content: "Non, jamais aucun frein. Vous ne savez pas à l’avance si un conducteur sera disponible, donc toujours faire une demande de trajet." },
    { title: "Qui sont les conducteurs ?", content: "Des personnes souvent en activité qui proposent des trajets et de jeunes retraités qui se mettent à la disposition des habitants." },
    { title: "Sur quels territoires êtes-vous présents ?", content: "Atchoum est déjà présent sur 20 départements et certainement autour de 30 en fin d’année 2024." },
    { title: "Comment mettre en place Atchoum ?", content: "Pour plus d’informations, nous vous invitons à nous contacter par email : contact@atchoum.eu." },
    { title: "En quoi se différencie Atchoum par rapport aux solutions existantes ?", content: "Atchoum est une véritable solution de mobilité rurale 2-en-1, sans commission. Atchoum dispose d’un site web de mise en relation, complété d’un centre d’appels téléphonique basé en France, et de tickets mobilité pour les personnes ne souhaitant ou ne pouvant pas payer en carte bancaire." },
    { title: "Comment mettre en place les tickets mobilité ?", content: "La collectivité ou toute autre structure désignée, achète des tickets mobilité auprès d’Atchoum et les revend." },
    { title: "Peut-on mettre en place Atchoum sans être AOM (Autorité Organisatrice de la Mobilité) ?", content: "OUI, pour plus d’informations, nous vous invitons à nous contacter par email : contact@atchoum.eu." }
];


  // Définir combien de FAQ afficher en fonction de l'état showAll
  const displayedFaqs = showAll ? Faqs : Faqs.slice(0, 7);

  return (
    <div className='container w-50-lg mt-42'>
      <h2>FAQ</h2>
      <div className="mt-16">
        {displayedFaqs.map((faq, index) => (
          <div key={index} className={`faq-item ${open[index] ? 'open-faq' : ''}`}>
            <button
              onClick={() => toggleCollapse(index)}
              aria-controls={`faq-collapse-text-${index}`}
              aria-expanded={open[index]}
              className={`faq-title w-100 d-flex justify-content-between align-items-center ${open[index] ? 'border-b pb-16' : ''}`}
            >
              {faq.title}
              <span className={`arrow ${open[index] ? 'open' : ''}`}>
                <img src={ArrowUp} alt="arrow up icon" loading="lazy" />
              </span>
            </button>
            <Collapse in={open[index]}>
              <div id={`faq-collapse-text-${index}`} className="faq-content mt-16">
                {faq.content}
              </div>
            </Collapse>
          </div>
        ))}
      </div>

      <div className="mt-24 text-center">
        <button className="cta" onClick={() => setShowAll(!showAll)}>
          {showAll ? "Voir moins de questions" : "Voir plus de questions"}
        </button>
      </div>
    </div>
  );
};

export default Faq;
