import { useEffect, useState } from "react";
import Footer from "../components/footer";
import Header from "../components/header";
import Services from "../services/global.service";
import TopBar from "../components/topBar";

const Layout = ({ children }) => {
  //    console.log(children)
  const [connected, setConnected] = useState(null);

  useEffect(() => {
    const auth = localStorage.getItem("token");
    if (auth) {
      Services.getAll("check-token")
        .then((res) => {
          //  console.log('check',res.data);
          if (res.data.status === "success") {
            setConnected(true);
          } else {
            setConnected(false);
          }
        })
        .catch((err) => {
          setConnected(false);
        });
    } else {
      return setConnected(false);
    }
  }, [connected]);
  return (
    <div>
      {connected !== null && <Header connected={connected} />}
      <div className="col-lg-12 mx-auto bg-auth">{children}</div>
      {connected && <TopBar />}
      <Footer />
    </div>
  );
};
export default Layout;
