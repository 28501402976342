import { useEffect, useState } from "react";
import NOImage from "../../assets/images/no-image.svg";
import Tears from "../../assets/images/tearsProfil.svg";
import Pen from "../../assets/images/pen.svg";
import ArrowRight from "../../assets/images/Arrow-right.svg";
import Completed from "../../assets/images/completed.svg";
import Services from "../../services/global.service";
import ModalSolidaire from "../../components/modalSolidaire";
import { useNavigate } from "react-router-dom";
import { calculateAge } from "../../utils/utils";
import { toast } from "react-toastify";

const Profil = ({ user }) => {
  const navigate = useNavigate();
  const [image, setImage] = useState(null);
  const [open, setOpen] = useState(false);
  const [completed, setCompleted] = useState(false);
  const mineur =calculateAge(user?.date_naissance)<18;
  // console.log({mineur})
  const [hasAllRequiredDocs, setHasAllRequiredDocs] = useState(false);
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("photo_profil", file);
    Services.create(formData, "update_user")
      .then((res) => {
        // console.log(res);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          draggable: true,
          progress: undefined,
         // theme: 'light', // Choose theme (light/dark)
          className: 'custom-toast-error'
  
        });
        // console.log(err);
      });

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result); // Le résultat de la lecture du fichier
      };
      reader.readAsDataURL(file); // Lire l'image comme une URL de données
    } else {
      setImage(null); // Réinitialise si aucune image n'est sélectionnée
    }
  };
  useEffect(() => {
    const requiredDocs = ["permis_de_conduire", "carte_grise", "attestation_assurance", "piece_identite"];

// Check if each required type has at least one entry
const hasAllRequired = requiredDocs.every(docType =>
    user?.documents.some(doc => doc.name === docType)
);
setHasAllRequiredDocs(hasAllRequired)


    if (user?.photo_profil.length > 0) {
      //in backend delete {} empty
      setImage(user?.photo_profil);
    } else {
      setImage(null);
    }
    if (
      user?.description !== "" &&
      user?.documents?.length > 3 &&
      user?.vehicule
    ) {
      setCompleted(true);
    }
  }, [open]);
  const finaliserProfil = () => {
    if (!user?.vehicule) {
      navigate("/vehicule-solidaire");
    } else if (user?.communes?.length === 0) {
      navigate("/perimetre-solidaire");
    } else if (user?.docs?.length === 0) {
      navigate("/docs-solidaire");
    }
  };
  return (
    <>
      <ModalSolidaire open={open} setOpen={setOpen} />
      <div className=" bg-grey">
        <div className="container col-lg-6 mx-auto">
          <div className="image-upload pt-16">
            <div className=" position-relative icon-container-profil">
              <div className="image-preview  ">
                {image ? (
                  <img
                    src={image}
                    alt="Aperçu"
                    className="image-preview__image"
                  />
                ) : (
                  <img src={NOImage} alt="NOImage" className="w-auto" />
                )}
              </div>
              <img src={Tears} alt="Icon" className="floating-icon-profil" />
              <div className="circle-pen">
                <img
                  src={Pen}
                  alt="Edit Icon"
                  className="edit-icon"
                  onClick={() => document.getElementById("fileInput").click()} // Simule un clic sur l'input file
                />
              </div>
            </div>
            {/* Champ input de fichier caché */}
            <input
              type="file"
              id="fileInput"
              accept="image/*"
              onChange={handleImageChange}
              style={{ display: "none" }} // Masquer l'input file
            />
            <p
              className="exergue pt-10"
              style={{ textTransform: "capitalize" }}
            >
              {user?.prenom}
              {completed && (
                <img src={Completed} alt="profil completed" className="ml-8" />
              )}
            </p>
          </div>
          <a href="/aboutme" className="no-decoration cursor-pointer">
            <div className="card-atch mt-16">
              <p className="exergue bleuM500 m-0 d-flex justify-content-between align-items-center">
                A propos de moi
                <img src={ArrowRight} alt="Arrow right" className="" />
              </p>
              {!user?.description && (
                <p className="current-text bleu400 m-0">
                  Ajoutez des informations sur vous et vos observation{" "}
                </p>
              )}
            </div>
          </a>
          <a href="/myDocs" className="no-decoration cursor-pointer">
            <div className="card-atch mt-8">
              <p className="exergue bleuM500 m-0 d-flex justify-content-between align-items-center">
                Mes documents
                <img src={ArrowRight} alt="Arrow right" className="" />
              </p>
              {!hasAllRequiredDocs && (
                <p className="current-text bleu400 m-0">
                   Ajoutez vos documents pour devenir conducteur confirmé et ainsi rassurer vos passagers
                </p>
              )}
            </div>
          </a>
          <a href="/ma-vehicule" className="no-decoration cursor-pointer">
            <div className="card-atch mt-8">
              <p className="exergue bleuM500 m-0 d-flex justify-content-between align-items-center">
                Mon véhicule
                <img src={ArrowRight} alt="Arrow right" className="" />
              </p>
              {!user?.vehicule && (
                <p className="current-text bleu400 m-0">
                  Ajoutez des informations sur votre véhicule pour aider les
                  passagers à vous reconnaitre plus facilement{" "}
                </p>
              )}
            </div>
          </a>
          {user?.conducteur_solidaire ? 
            <a href="/deplacement" className="no-decoration cursor-pointer">
              <div className="card-atch mt-8">
                <p className="exergue bleuM500 m-0 d-flex justify-content-between align-items-center">
                  Mon périmètre de déplacement
                  <img src={ArrowRight} alt="Arrow right" className="" />
                </p>
              </div>
            </a>
          :''}
          {!mineur  &&(
          user && !user?.conducteur_solidaire && !user?.demande_solidaire ? (
            <div className="solidaire mt-32 mb-80 ">
              <p className="subtitle text-start">
                Vous souhaitez rendre service et recevoir les demandes de trajet
                sur votre territoire ?
              </p>
              <p className="current-text text-start">
                Vous serez conducteur solidaire et indemnisé à hauteur de 0.32€
                du km.
              </p>
              <button
                className="btn-secondary-s p-14 w-100 mt-16"
                onClick={() => {
                  navigate("/bulletin-adhesion");
                }}
              >
                Devenir conducteur solidaire
              </button>
            </div>
          ) : user?.communes?.length > 0 && user?.conducteur_solidaire ? (
            <>
              <div className="h-xs-100"></div>
              <button
                className="btn-secondary-s p-14 w-100"
                onClick={() => {
                  setOpen(!open);
                }}
              >
                Ne plus être conducteur solidaire
              </button>
            </>
          ) : user && !user?.conducteur_solidaire && user?.demande_solidaire ?
          (
            <div className="solidaire mt-32 mb-80">
            <p className="subtitle text-start">Inscription solidiare en cours de validation</p>
            <button
                className="btn-secondary-s p-14 w-100 mt-16"
                onClick={() => {
                  setOpen(!open);
                }}
              >
                Annuler ma démarche
              </button>
            </div>
          )
          :(
            <div className="solidaire mt-32 mb-80">
              <p className="subtitle text-start">
                Plus que quelques étapes avant de devenir conducteur solidaire
              </p>
              <p className="current-text text-start">
                Complétez votre profil pour être averti lorsqu'un passager
                recherche un conducteur solidaire.
              </p>
              <button
                className="btn-primary-s p-14 w-100 mt-8"
                onClick={finaliserProfil}
              >
                Finaliser mon profil
              </button>
              <button
                className="btn-secondary-s p-14 w-100 mt-8"
                onClick={() => {
                  setOpen(!open);
                }}
              >
                Annuler ma démarche
              </button>
            </div>
          ))
      }
          <div className="h-xs-100"></div>
        </div>
      </div>
    </>
  );
};
export default Profil;
