import "../../assets/styles/home.scss";
import { useEffect, useState } from "react";
import { jwtDecode } from "jwt-decode";
// import "core-js/stable/atob";
import Services from "../../services/global.service";
import Footer from "../../components/footer";
import Header from "../../components/header";
import Headerblue from "../../components/headerblue";
import { useLocation } from "react-router-dom";
import BlocTrajet from "../../components/blocTrajet";
import TopBar from "../../components/topBar";
import BlocResultEmpty from "../../components/BlocResultEmpty";
import { useMyContext } from "../../context";
import { HelmetProvider, Helmet } from "react-helmet-async";

const SearchInterface = () => {
  const [connected, setConnected] = useState(null);
  const { userData } = useMyContext();
  const location = useLocation();
  const data = location?.state?.searchData;
  const inputs_data = location?.state?.inputs_data;
  // console.log('data search', userData)
  useEffect(() => {
    const auth = userData ? userData?.token : localStorage.getItem("token");
    // console.log({auth})
    if (auth) {
      Services.getAllWithToken("check-token", auth)
        .then((res) => {
          // console.log('check', res.data);
          if (res.data.status === "success") {
            setConnected(true);
          } else {
            setConnected(false);
          }
        })
        .catch((err) => {
          setConnected(false);
        });
    } else {
      return setConnected(false);
    }
  }, [connected, userData, data]);

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Page de recherche</title>
          <meta name="description" content="page de recherche covoiturage" />
        </Helmet>
      </HelmetProvider>

      {connected !== null && <Header connected={connected} />}
      <Headerblue data={data.data} />
      <div className="bg-grey">
        <div className="p-16 container-fluid-lg">
          {data.resultat.length > 0 ? (
            <div className="row">
              {data.resultat.map((el) => {
                return (
                  <BlocTrajet
                    data={el}
                    path="/le-trajet/"
                    inputs_data={inputs_data}
                    connected={connected}
                  />
                );
              })}
              <div className="col-lg-6 mx-auto">
                <BlocResultEmpty
                  title={"Aucun trajet ne vous convient ?"}
                  link={"/"}
                  className={"exergue mt-26"}
                  data={data}
                />
              </div>
            </div>
          ) : (
            <div className="col-lg-6 mx-auto">
              <BlocResultEmpty
                title={"Aucun résultat pour votre trajet recherché ..."}
                link={"/"}
                className={"subtitle"}
                data={data}
              />
            </div>
          )}
        </div>
        <div className="h-xs-100"></div>
      </div>

      {connected && <TopBar />}
      {/* <Footer /> */}
    </>
  );
};
export default SearchInterface;
