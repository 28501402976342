import React, { useState } from "react";
import Autocomplete, { geocodeByAddress, getLatLng } from "react-places-autocomplete";

const AutocompleteAdr = ({ depart, setDepart, handleSelect, type }) => {
  const [showHistoric, setShowHistoric] = useState(false);
  const [showSuggestion, setShowSuggestion] = useState(false);

  // Retrieve and parse the address history from localStorage
  const addressHistoryKey = "addressHistory";
  const historic = JSON.parse(localStorage.getItem(addressHistoryKey)) || [];

  // Filter the historic addresses to remove duplicates
  const uniqueHistoric = historic.filter(
    (value, index, self) =>
      index === self.findIndex((t) => t.address === value.address)
  );

  const searchOptions = {
    componentRestrictions: { country: ["fr"] },
    types: ["geocode", "establishment"],
  };

  const handleSuggestionSelect = (addr) => {
    setDepart(addr); // Update the input value
    handleSelect(addr, type); // Call the provided handleSelect function
    setShowHistoric(false); // Hide all lists after selection
    setShowSuggestion(false);
  };

  return (
    <div className="autocomplete-wrapper">
      <Autocomplete
        value={depart}
        searchOptions={searchOptions}
        onChange={(value) => {
          setDepart(value);
          setShowHistoric(false); // Hide historic on typing
          setShowSuggestion(true); // Show suggestions when typing
        }}
        onSelect={(addr) => handleSuggestionSelect(addr)}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div className="floating-input-container">
            <input
              {...getInputProps({
                placeholder: "",
                className: `floating-input input-location input-home ${
                  type === "depart" ? "radius-top" : "radius-bottom"
                }`,
                onFocus: () => {
                  setShowHistoric(true);
                  setShowSuggestion(true);
                }, // Show historic and suggestions on focus
                onBlur: () => setTimeout(() => {
                  setShowHistoric(false);
                  setShowSuggestion(false);
                }, 200), // Hide historic and suggestions after a slight delay on blur
              })}
              id="floatingAdr"
            />
            <label htmlFor="floatingAdr" className="floating-label home-label">
              Adresse {type === "depart" ? "de départ" : "d’arrivée"}
            </label>
            <div
              className={
                suggestions.length > 0 || showHistoric ? "container-autocomplete" : ""
              }
            >
              {showHistoric && (
                <div className="historic-container">
                  <p className="text-start pb-8 border-b">Récemment recherché ...</p>
                  {uniqueHistoric.map((history, index) => (
                    <div
                      key={`history-${index}`}
                      className="mapItem historic-item"
                      onClick={() => handleSuggestionSelect(history.address)}
                      style={{ backgroundColor: "#fff", cursor: "pointer" }}
                    >
                      {history.address}
                    </div>
                  ))}
                </div>
              )}
              {loading ? <div>Loading...</div> : null}
              {showSuggestion && suggestions.map((suggestion, index) => {
                const style = {
                  backgroundColor: suggestion.active ? "#41B6E6" : "#fff",
                };
                return (
                  <div
                    key={`suggestion-${index}`}
                    className="mapItem"
                    {...getSuggestionItemProps(suggestion, { style })}
                    onClick={() => handleSuggestionSelect(suggestion.description)} // Close list and select suggestion
                  >
                    {suggestion.description}
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </Autocomplete>
    </div>
  );
};

export default AutocompleteAdr;