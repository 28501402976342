import { useLocation, useNavigate, useParams } from "react-router-dom";
import ReturnIcon from "../../assets/images/return.svg";
import Services from "../../services/global.service";

import { useEffect } from "react";
import { useState } from "react";
import RaisonSignal from "../../components/modalRaison";
import Conducteur from "../../components/conducteur";
import { HelmetProvider ,Helmet} from "react-helmet-async";
import Loader from "../../components/loader";
const LeConducteur = () => {
  const navigate = useNavigate();
  const params=useParams()
  const location=useLocation()
  const searchData=location?.state?.searchData
  const [conducteur,setConducteur]=useState()
  const [open,setOpen]=useState(false)
  const [loading,setLoading]=useState(false)
  const [raison,setRaison]=useState("")
  const getDetails=()=>{
    const token=localStorage.getItem('token')
    Services.getAllWithToken(`users/${params.id}`,token)
    .then((res)=>{
        // console.log(res)
        setConducteur(res.data.data)
    })
    .catch((err)=>{
        // console.log(err)
    })
  }

  const handleDemandSolidaire=()=>{
    setLoading(true)
    // api demand trajet solidaire
    const trajet={
      prixTrajet:parseFloat(searchData?.prix),
      searchData
    }
    navigate(`/paymentMethod`, { state: { trajet , conducteur_id:conducteur?.id } });
   // window.location.href=`${process.env.REACT_APP_BASE_URL_FRONT_DEV}confirmation-demande`
  }
  useEffect(()=>{
    getDetails()
  },[])

 
  return (
    <>
    <HelmetProvider>
        <Helmet>
            <title>Détails conducteur</title>
            <meta name="description" content="Détails d'un conducteur " />
            
        </Helmet>
    </HelmetProvider>
      
   <RaisonSignal open={open} setOpen={setOpen} raison={raison} setRaison={setRaison} user={conducteur?.id} />
      <div className="bg-grey">
        <div className="letrajet">
          <div className="container-fluid-lg p-16 col-lg-6 mx-auto">
            <div className="col-auto">
              <h1 className="text-white d-flex align-items-center">
                <button onClick={() => navigate(-1)} className="cta">
                  <img src={ReturnIcon} className="mr-8" alt="return icon" />
                </button>
                Le Conducteur
              </h1>
            </div>
        <div className="mt-24">
            {conducteur ? 
              <Conducteur conducteur={conducteur} open={open} setOpen={setOpen} title={false} />
           
           :
           <Loader /> }
        </div>
         
          </div>
        </div>
      </div>
      <div className="h-xs-100"></div>
      <div className="bottom-white-block">
          <button
            className="btn-primary-s p-16 w-sm-100"
            onClick={handleDemandSolidaire} 
            disabled={params.id==localStorage.getItem('userData') || loading}
          >
           {loading ? '... Demande en cours' :'Faire une demande de trajet'} 
          </button>
        </div>
    </>
  );
};
export default LeConducteur;
