


import Layout from "./layout";
import { HelmetProvider ,Helmet} from "react-helmet-async";
const NotFoundRoute = () => {
  return (
    <Layout>
      <HelmetProvider>
       <Helmet>
            <title>Page 404 </title>
            <meta name="description" content="page ou path non trouvé  " />
        </Helmet>
        </HelmetProvider>
      <div className="bg-grey min-h-50 ">
        <div className="not-found-img pb-80 container d-flex align-items-center">
          <div className="row">
            <div className="col-lg-8 mx-auto text-start mt-32">
              <h5 className="bleuM500 text-center">Oh oh, la page que vous cherchez n’existe pas ...</h5>

              <div className="mt-42 pb-16  text-center">
                <a className="cta" href="/">Retour à la page d’accueil</a>
              </div> 
            </div>
            <div className="col-md-6 mt-24">
              {/* <img src={imgnotFound} alt=''></img> */}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default NotFoundRoute;
