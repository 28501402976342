import { Modal, FormCheck } from "react-bootstrap";
import { useState } from "react";
import Calendar from "react-calendar";
import '../assets/styles/modalForm.scss';
import Closesvg from '../assets/images/close.svg';

import leftArrow from '../assets/images/arrow-up-left.png';
import rightArrow from '../assets/images/arrow-up-right.png';
import { toast } from "react-toastify";

const CalendarAtchoum = ({
  open,
  setOpen,
  home,
  type,
  setConfirmationDetails,
  confirmationDetails,
  
}) => {


  const detailsForType = confirmationDetails[type];
  //  console.log('confirmationDetails',confirmationDetails)
    const [isFlexible,setIsFlexible]=useState(!home ? false : detailsForType?.isFlexible || false)
    const [selectedDate, setSelectedDate] = useState(
      detailsForType?.formattedDateform ? detailsForType.formattedDateform : new Date()
    );
//     const [selectedDate, setSelectedDate] = useState(() => {
//   const currentDate = new Date();
//   return detailsForType?.formattedDateform
//     ? new Date(detailsForType.formattedDateform)
//     : currentDate;
// });
    const [selectedDays, setSelectedDays] = useState(detailsForType?.selectedDays || []);
    const [selectedPeriods, setSelectedPeriods] = useState(detailsForType?.selectedPeriods || []);
    // console.log('hhhh',confirmationDetails?.retour?.date)
    const minDate = confirmationDetails?.depart?.date || confirmationDetails?.retour?.date
  ? type === "depart"
    ?new Date() // Fallback si retour.date est undefined
    : confirmationDetails?.depart?.date
      ? new Date(confirmationDetails.depart.date)
      : new Date() // Fallback si depart.date est undefined
  : new Date(); // Si aucun date n'existe, fallback sur la date actuelle

  // console.log(detailsForType)
    const [time, setTime] = useState({
        hour: detailsForType?.time?.hour || new Date().getHours(),
        minute: detailsForType?.time?.minute || new Date().getMinutes()
      });
     
      const [currentHour, setCurrentHour] = useState(new Date().getHours());
      const [currentMinute, setCurrentMinute] = useState(new Date().getMinutes());
      const handleChange = (e) => {
        const { name, value } = e.target;
        // console.log(name, value )
        setTime((prevTime) => ({
          ...prevTime,
          [name]: value
        }));
      };
  // State to track selected days
  const customShortWeekday = (locale, date) => {
    const days = ['D', 'L', 'M', 'M', 'J', 'V', 'S'];
    return days[date.getDay()];
  };
  const handleDayChange = (e) => {
    const value = e.target.value;
    setSelectedDays((prevSelectedDays) =>
      prevSelectedDays.includes(value)
        ? prevSelectedDays.filter((day) => day !== value)
        : [...prevSelectedDays, value]
    );
    // setCurrentMinute('00')
  };

  const handlePeriodChange = (e) => {
    const value = e.target.value;
    setSelectedPeriods((prevSelectedPeriods) =>
      prevSelectedPeriods.includes(value)
        ? prevSelectedPeriods.filter((period) => period !== value)
        : [...prevSelectedPeriods, value]
    );
  };
  const handleDateChange = (date) => {
    // Example formatting using 'fr-FR' locale
    // Set the selected date
    setSelectedDate(new Date(date));
     //setTime({hour:'08',minute:currentMinute})
  };
  const handleSwitchChange = () => {
    //setIsCheckedmodal((prevState) => !prevState);
    setIsFlexible(!isFlexible)
    if(isFlexible)  setSelectedDate(null)
  };

  const formatDisplayDate = (date) => {
    const options = { weekday: 'long', day: 'numeric', month: 'long' };
    return new Intl.DateTimeFormat('fr-FR', options).format(date);
  };

  const formatValueDate = (date) => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const generateDates = () => {
    const today = confirmationDetails?.depart?.date 
    ? new Date(confirmationDetails.depart.date) 
    : new Date();
    let dates = [];
    for (let i = 0; i < 6; i++) {
      const futureDate = new Date(today);
      futureDate.setDate(today.getDate() + i);
      dates.push({ display: formatDisplayDate(futureDate), value: formatValueDate(futureDate) });
    }
    return dates;
  };

  const dates = generateDates();

  const generateTimeOptions = (max, min) => {
    // console.log({min,max})
    const options = [];
    for (let i = min; i <= max; i++) {
      const value = i < 10 ? `0${i}` : `${i}`;
      options.push(
        <option key={value} value={value}>
          {value}
        </option>
      );
    }
    return options;
  };
  const parseDate = (dateStr) => {
    const [day, month, year] = dateStr.split("/"); 
    return new Date(`${year}-${month}-${day}`); // Convert to "YYYY-MM-DD" format
  };
  
  const handleConfirm = () => {
    // Get selected time values (assumed to be in state)

  
    const [day, month, year] = new Date(selectedDate)
    .toLocaleDateString('fr-FR', { day: '2-digit', month: '2-digit', year: 'numeric' })
    .split('/');

     const formattedDateform = `${year}-${month}-${day}`;
      //  console.log(formattedDateform)
      // Retrieve departure & return details
  const departDate = confirmationDetails?.depart?.formattedDateform;
  const retourDate = confirmationDetails?.retour?.formattedDateform;
      
        // **Validation: Departure Date Cannot Be After Return Date**
        if (type === "depart" && !isFlexible && !confirmationDetails?.retour?.isFlexible && retourDate && formattedDateform > retourDate) {
          toast.error("La date de départ ne peut pas être postérieure à la date de retour.");
          return;
        }

        if (type === "retour" && !isFlexible && departDate && formattedDateform < departDate) {
          toast.error("La date de retour ne peut pas être antérieure à la date de départ.");
          return;
        }
        // If flexible, check conflicts with selectedDates

        if (type === "depart" && retourDate) {
          const selectedDays = confirmationDetails?.retour?.selectedDays || [];
          const formattedSelectedDates = selectedDays.map(date => parseDate(date).getTime());
          const retourTimestamp = new Date(formattedDateform).getTime();
          // console.log('formattedSelectedDates depart',formattedSelectedDates)
          // console.log('retourTimestamp depart',retourTimestamp)
          // has conflit if date flexible < date depart
          const hasConflict = formattedSelectedDates.some(date => date < retourTimestamp);
          
          if (hasConflict) {
            toast.error("Une des dates sélectionnées pour le départ ne peut pas être postérieure à la date de retour.");
            return;
          }
        }

  if (type === "retour" && departDate) {
  const formattedSelectedDates =  confirmationDetails?.depart?.selectedDays.map(date =>  parseDate(date).getTime());
// console.log(formattedSelectedDates,new Date(formattedDateform).getTime())
    const hasConflict = formattedSelectedDates.some(date => date < new Date(formattedDateform).getTime());
    if (hasConflict) {
      toast.error("Une des dates sélectionnées pour le retour ne peut pas être antérieure à la date de départ.");
      return;
    }
  }

        if (type === "retour"  && !isFlexible) {
          if (formattedDateform === confirmationDetails?.depart?.formattedDateform) {
            const departHour = confirmationDetails?.depart?.time?.hour;
            const departMinute = confirmationDetails?.depart?.time?.minute;
        
            if (
              time?.hour < departHour || 
              (time?.hour === departHour && time?.minute <= departMinute)
            ) {
              toast.error("L'heure de retour doit être postérieure à l'heure de départ");
              return;
            }
          }
        }
       
        if (type === "depart"  && !isFlexible) {
          if (formattedDateform === confirmationDetails?.retour?.formattedDateform) {
            const retourHour = confirmationDetails?.retour?.time?.hour;
            const retourMinute = confirmationDetails?.retour?.time?.minute;
        
            if (
              time?.hour > retourHour || 
              (time?.hour === retourHour && time?.minute >= retourMinute)
            ) {
              toast.error("L'heure de départ doit être antérieure à l'heure de retour");
              return;
            }
          }
          
        }
        
    const newConfirmationDetails = {
      formattedDateform: formattedDateform,
      date: isFlexible ? null:`${formattedDateform} ${time.hour.toString().padStart(2, '0')}:${time.minute.toString().padStart(2, '0')}:00`,
      time: isFlexible ? null :time,
      isFlexible,
      selectedDays: isFlexible ? selectedDays : [],
      selectedPeriods: isFlexible ? selectedPeriods : [],
    };

    // console.log('Confirmation Details:', newConfirmationDetails);
    setConfirmationDetails((prevState) => ({
      ...prevState,
      [type]: { ...newConfirmationDetails } // This will update either `depart` or `arrive` based on `type`
    }));
  
    setOpen(false)
    // Here you can also implement any additional logic, such as sending this data to a parent component or API
  };



  return (
    <>
      <Modal
        show={open}
        onHide={() => {
          setOpen(false);
        }}
      >
        <Modal.Header>
        
          <Modal.Title>
            <h1>Date et heure  {type==="depart" ? "de départ" : "de retour"}</h1>
          </Modal.Title>
          <button
            className="closeCta"
            onClick={() => setOpen(false)}
          >
            <img src={Closesvg}  alt="icon close" loading="lazy"/>
          </button>
        </Modal.Header>

        <div className="p-16 popup">
          {home && <div className="col">
            <div className="custom-switch custom-switch-label-io d-flex align-items-center">
              <FormCheck
                type="switch"
                id="custom-switch"
                className="form-switch mr-8"
                checked={isFlexible}
                onChange={handleSwitchChange}
              />
              <label className="legend" htmlFor="custom-switch">
                Mes disponibilités sont flexibles
              </label>
            </div>  
          </div>}
        </div>
         
        <div style={{ display: isFlexible && isFlexible  ? 'none' : 'block' }}>
          <Modal.Body>
            <Calendar 
            onChange={handleDateChange} 
            value={selectedDate} 
            minDate={minDate} // Set minimum date
            maxDate={type === "depart" && confirmationDetails?.retour?.date 
              ? new Date(confirmationDetails.retour.date) 
              : null}
            formatShortWeekday={customShortWeekday}
              prevLabel={<img src={leftArrow} alt="Previous" className="arrow "  loading="lazy"/>}
              nextLabel={<img src={rightArrow} alt="Next" className="arrow"  loading="lazy"/>}
            />
            
              <div className="time">Partir à
          <select name="hour" className="w-auto form-control" 
            onChange={handleChange} 
            value={time.hour} // Bind to state
          >
          {generateTimeOptions(23, new Date(selectedDate) instanceof Date && new Date(selectedDate)?.toDateString() === new Date().toDateString() ? currentHour : 0)}

          </select>
          :
          <select name="minute" className="w-auto form-control" 
            onChange={handleChange} 
            value={time.minute} // Bind to state
          >
           {generateTimeOptions(59, time?.hour === currentHour?.toString() && new Date(selectedDate) instanceof Date && new Date(selectedDate)?.toDateString() === new Date().toDateString()  ? currentMinute : 0)}
          </select>
         
        </div>
          </Modal.Body>

          <Modal.Footer>
            <button
              className="btn-primary-s w-100"
              onClick={() => {
                //updateFormvalue();
                handleConfirm()
                //setOpen(false);
              }}
             // disabled={ctadisabled}
            >
              Confirmer la date et l'heure
            </button>
          <div className="h-xs-100"></div>

          </Modal.Footer>
        </div>

        {/* Flexible options */}
        <div style={{ display: !isFlexible || !home ? 'none' : 'block' }}>
          <Modal.Body>
            <h2>Quand voulez vous partir ?</h2>
            <label className="titleLabel">Quel jour ?</label>
            {dates.map((date, index) => (
              <div className="form-group text-start mt-16" key={index}>
                <label className="d-flex align-items-center current-text capitalise">
                  <input
                    type="checkbox"
                    name="daycheck"
                    id={`day-${index}`}
                    value={date.value}
                    checked={selectedDays.includes(date.value)} // Determine if checkbox is checked
                    onChange={handleDayChange} // Handle checkbox change
                  />
                  <span className="custom-checkbox"></span> {date.display}
                </label>
              </div>
            ))}

            <label className="titleLabel">Période de la journée</label>
            {['Matin', 'Après-midi', 'Soir'].map((period, index) => (
              <div className="form-group text-start mt-16" key={index}>
                <label className="d-flex align-items-center current-text capitalise">
                  <input
                    type="checkbox"
                    name="period"
                    id={`period-${index}`}
                    value={period}
                    checked={selectedPeriods.includes(period)} // Determine if checkbox is checked
                    onChange={handlePeriodChange} // Handle checkbox change
                  />
                  <span className="custom-checkbox"></span> {period}
                </label>
              </div>
            ))}
          </Modal.Body>

          <Modal.Footer>
            <button
              className="btn-primary-s w-100"
              onClick={() => {
                handleConfirm()
               // updateFormvaluecheck();
                setOpen(false);
              }}
              // disabled={ctadisabled}
            >
              Confirmer la période
            </button>
            <div className="h-xs-100"></div>

          </Modal.Footer>
        </div>
      </Modal>
    </>
  );
};

export default CalendarAtchoum;
