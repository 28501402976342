import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import IconClose from '../assets/images/iconClose.svg';
import Form from 'react-bootstrap/Form';
import Services from '../services/global.service';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

function Cancele({ open, setOpen,idTrajet,text,idReservation}) {
  
  const navigate=useNavigate()

  const [raison, setRaison]=useState()

  
  const HandleSubmit=()=>{
    const data={
      canceledRaison:raison,
      // canceledBy:localStorage.getItem('userData'),
      idTrajet,
    }
    Services.create(data,`AnnulationTrajet`)
    .then((res)=>{
      // console.log(res)
     setOpen(!open)
     navigate(-1)

    })
    .catch((err)=>{
      toast.error(err?.response?.data?.message, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
       // theme: 'light', // Choose theme (light/dark)
        className: 'custom-toast-error'

      });
      // console.log(err)
    })
  }
  
  return (
    <Modal show={open} onHide={() => setOpen(false)} centered>
      <Modal.Header className="border-0 d-flex justify-content-between align-items-start">
        <h2 className="bleuM500">Pourquoi annulez-vous le trajet ?</h2>
        <button className="cta" onClick={() => setOpen(false)}>
          <img src={IconClose} alt="icon close" loading="lazy"/>
        </button>
      </Modal.Header>

      <Modal.Body>
        <p className="exergue bleuM500">{text}</p>
       
        
            <div  className="floating-input-container mt-24">
            <input
              type="text"
              value={raison}
              onChange={(e) => setRaison(e.target.value)}
              className={`floating-input p-16`} 
              placeholder=" " // important for floating effect
            />
            <label className="floating-label">
            Quelle est la raison ?
            </label>
            </div>
            <button className="btn-primary-s p-16 w-100 mt-24" onClick={()=>HandleSubmit()}>
            J’annule le trajet
            </button>
  
      </Modal.Body>

      
    </Modal>
  );
}

export default Cancele;
