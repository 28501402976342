import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../components/header";
import ReturnIcon from "../../assets/images/return.svg";
import Services from "../../services/global.service";
import Collapse from "react-bootstrap/Collapse";
import ArrowUp from "../../assets/images/arrow-up.svg"; // Replace with actual path to arrow icon
import To from "../../assets/images/to.svg"; // Replace with actual path to arrow icon
import CreditCard from "../../assets/images/credit-card-1.svg";
import Tickets from "../../assets/images/ticket-check.svg";
import { HelmetProvider ,Helmet} from "react-helmet-async";
const MyPaiements = () => {
  const navigate = useNavigate();
  const [historic, setHistoric] = useState([]);

  const getMyPaiements = () => {
    Services.getAll("reservations/user")
      .then((res) => {
        // console.log(res?.data);
        setHistoric([...res?.data?.reservations,...res?.data?.assurancePayments] || []);
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  useEffect(() => {
    getMyPaiements();
  }, []);
  const groupPaymentsByMonth = (payments) => {
    return payments?.reduce((groups, payment) => {
      const date = new Date(payment?.created_at || payment?.date);
      const monthYear = new Intl.DateTimeFormat("fr-FR", {
        year: "numeric",
        month: "long",
      }).format(date);

      if (!groups[monthYear]) {
        groups[monthYear] = [];
      }
      groups[monthYear]?.push(payment);
      return groups;
    }, {});
  };

  const groupedPayments = groupPaymentsByMonth(historic);
 
  return (
    <>
    <HelmetProvider>
      <Helmet>
            <title>Page mes paiements </title>
            <meta name="description" content="mes paiements des trajets (par CB et par tickets mobilités)" />
          </Helmet>
    </HelmetProvider>
          
      <div className="desktop">
        <Header connected={true} />
      </div>
      <div className="bg-grey">
        <div className="bg-atchoum-profil">
          <div className="container-fluid-lg">
            <h1 className="text-white p-16 d-flex">
              <button onClick={() => navigate(-1)} className="cta">
                <img src={ReturnIcon} className="mr-8" alt="return icon" />
              </button>
              Mes paiements
            </h1>
          </div>
        </div>
        <div className="px-16 mt-8 col-lg-6 mx-auto">
  {Object.keys(groupedPayments).length > 0 ? (
    Object.entries(groupedPayments).map(([monthYear, payments], index) => (
      <div key={index}>
        <p className="subtitle">
          {monthYear?.charAt(0)?.toUpperCase() + monthYear?.slice(1)}
        </p>
        {payments?.map((el, idx) =>
          el?.methodPaiement === "card" || el?.numCard ? (
            <HistoricCard
              key={idx}
              date={el?.created_at}
              numCard={el?.numCard}
              reservation={el?.reservation_strip}
              route={el?.reservation_trajet?.communes ? JSON.parse(el?.reservation_trajet?.communes) : "Assurance"}
              amount={el?.price || el?.amount / 100}
            />
          ) : (
            <HistoricTicket
              key={idx}
              date={el?.created_at}
              route={el?.reservation_trajet?.communes ? JSON.parse(el?.reservation_trajet?.communes) : ""}
              amount={el?.price}
              reservation={el?.reservation_ticket}
            />
          )
        )}
      </div>
    ))
  ) : (
    <p className="text-center mt-16">Aucun paiement trouvé.</p>
  )}
</div>

      </div>
    </>
  );
};

// Component to render a card payment entry
const HistoricCard = ({ date, route, amount, reservation,numCard }) => (
  <div className="card-atch mt-16">
    <div className="d-flex justify-content-between border-b pb-16">
      <div>
        <p className="exergue my-0">{new Date(date)?.toLocaleDateString(
            "fr-FR",
            {
              day: "numeric",
              year: "numeric",
              month: "numeric",
            }
          )}</p>
        {route?.dept ? 
           <p className="exergue mt-8">
           {route?.dept} <img src={To} alt="To icon" className="mx-8" />{" "}
           {route?.dest}
         </p>
         :
        'Assurance optionnelle Atchoum'
           } 
      </div>
      <div>
        <p className="exergue">-{amount || 0}€</p>
      </div>
    </div>
    <p className="current-text mb-0 pt-16">
      {" "}
      <img src={CreditCard} className="mr-8" alt="credit Card icon" /> CB
      finissant par {reservation ? reservation?.numCard: numCard}
    </p>
  </div>
);

// Component to render a ticket payment entry with a collapsible section for ticket details
const HistoricTicket = ({ date, reservation, route, amount }) => {
  const [openPayment, setOpenPayment] = useState(false);

  return (
    <div className={`faq-item ${openPayment ? "open" : ""}`}>
      <button
        onClick={() => setOpenPayment(!openPayment)}
        aria-controls="faq-collapse-text"
        aria-expanded={openPayment}
        className={`faq-title  w-100  ${openPayment ? "border-b pb-16" : ""}`}
      >
        <div className="d-flex justify-content-between border-b pb-16">
          <div>
            <p className="exergue my-0">
              {new Date(date)?.toLocaleDateString(
            "fr-FR",
            {
              day: "numeric",
              year: "numeric",
              month: "numeric",
            }
          )}
            </p>
            <p className="exergue mt-8">
              {route?.dept} <img src={To} alt="To icon" className="mx-8" />{" "}
              {route?.dest}
            </p>
          </div>
          <div>
            <p className="exergue ">-{amount || 0}€</p>
          </div>
        </div>

        <div className="mt-16 d-flex justify-content-between">
          <p>
            <img src={Tickets} className="mr-8 " alt="tickets icon" />
            {reservation?.length} tickets mobilités
          </p>
          <span className={`arrow ${openPayment ? "open" : ""}`}>
            <img src={ArrowUp} alt="arrow up icon" />
          </span>
        </div>
      </button>
      <Collapse in={openPayment}>
        <div id="faq-collapse-text" className="faq-content mt-16">
          {reservation?.map((ticket, idx) => (
            <p key={idx} className="current-text bleu400">
              N°{ticket.refTicket}
            </p>
          ))}
        </div>
      </Collapse>
    </div>
  );
};

export default MyPaiements;
