import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ItemBlog from "./itemBlog";
import { useEffect, useState } from "react";
import Services from "../services/global.service";
import { toast } from "react-toastify";


const BlogHome = () => {
  const [articles,setArticles]=useState([])
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2.3, // Changed to 1 to avoid cutting off content in small screens
    },
  };

  const data = [
    {
      id: 1,
      image: "image.png",
      text: "Comprendre Atchoum",
      readingTime: "9 min",
    },
    {
      id: 2,
      image: "image.png",
      text: "Présentation Atchoum",
      readingTime: "9 min",
    },
    {
      id: 3,
      image: "image.png",
      text: "Présentation Atchoum",
      readingTime: "9 min",
    },
  ];
const getArticles=()=>{
  Services.getAll('videos')
  .then((res)=>{
    //  console.log(res?.data)
    setArticles(res?.data?.slice(0,3))
  })
  .catch((err)=>{
    // toast.error(err?.response?.data?.message, {
    //   position: 'top-right',
    //   autoClose: 3000,
    //   hideProgressBar: true,
    //   closeOnClick: true,
    //   draggable: true,
    //   progress: undefined,
    //  // theme: 'light', // Choose theme (light/dark)
    //   className: 'custom-toast-error'

    // });
    // console.log(err)
  })
}
useEffect(()=>{getArticles()},[])
  return (
    <div className="container mt-42">
      <h2>Les actualités</h2>
      <Carousel
        partialVisible={true}
        responsive={responsive}
        swipeable={true}
        draggable={true}
        showDots={false}  // Set to true if you want dots at the bottom
        infinite={false}   // Enables infinite looping of slides
        autoPlay={false}  // Enable auto play if you want the carousel to auto-advance
        keyBoardControl={true} // Allows keyboard navigation
        className="mt-16"
      >
        {articles?.map((item) => (
          <ItemBlog key={item?.id} data={item} />
        ))}
      </Carousel>
    </div>
  );
};

export default BlogHome;
