import { useState, useEffect } from "react";

const CardTrajet = (props) => {
  // console.log({props})
  const [timeDept, setTimeDept] = useState('');
  const [timeRetour, setTimeRetour] = useState('');
  const [formattedDate, setFormattedDate] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    if(props?.trajetType==="retour"){
      if (props?.trajet?.dateRetour) {
        formatDate(props?.trajet?.dateRetour);
      } 
      else if(props?.trajet?.isFlexibleDest){
        setFormattedDate('')
      }
    }
    else if(props?.trajetType==="aller"){
      if (props?.trajet?.dateDepart) {
        // console.log('im hereee')
        formatDate(props?.trajet?.dateDepart);
      } 
      else if(props?.trajet?.isFlexibleDep){
        // console.log('im hereee')
        setFormattedDate('')
      }
      else if(props?.trajet?.Trajetregulier ){
       
        setFormattedDate('Trajet régulier')
        setTimeDept(formatH(props?.trajet?.horaires[0]?.timeTrajet))
        setTimeRetour(formatTime(props?.trajet?.horaires[0]?.timeTrajet))
      }
    }
    
    
  }, [props.trajet?.dateDepart]); // Only run when dateDepart changes
  const formatH=(time)=>{
    const [hours, minutes] = time.split(":").map(Number);
    return `${hours.toString().padStart(2, "0")}h${minutes.toString().padStart(2, "0")}`;
  }
const formatTime=(timePart)=>{
  const [hours, minutes] = timePart.split(":").map(Number);

    const durationHours = parseInt(props?.trajet?.duration?.split("h")[0]) || 0;
    const durationMinutes = parseInt(props?.trajet?.duration?.split("h")[1]) || 0;

    // Calculate arrival time
    const totalMinutes = minutes + durationMinutes;
    const additionalHours = Math.floor(totalMinutes / 60);
    const finalMinutes = totalMinutes % 60;

    const totalHours = hours + durationHours + additionalHours;
    const finalHours = totalHours % 24; // Wrap around if hours exceed 24

    const deptTime = `${hours.toString().padStart(2, "0")}h${minutes
      .toString()
      .padStart(2, "0")}`;
    const arrTime = `${finalHours.toString().padStart(2, "0")}h${finalMinutes
      .toString()
      .padStart(2, "0")}`;
      return arrTime;
       
}
  const formatDate = (dateString) => {
    // Parse the date string to a Date object
    const date = new Date(dateString);

    // Get individual components using Date methods
    const day = date.getDate(); // Day of the month
    const month = date.getMonth(); // Month (0-11)
    const dayOfWeek = date.getDay(); // Day of the week (0-6)
    const hours = date.getHours(); // Hours (0-23)
    const minutes = date.getMinutes(); // Minutes (0-59)

    // Day and month names
    const daysOfWeek = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'];
    const monthNames = ['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre'];

    // Get the names
    const dayName = daysOfWeek[dayOfWeek];
    const monthName = monthNames[month];
    const durationHours = parseInt(props?.trajet?.duration?.split("h")[0]) || 0;
    const durationMinutes = parseInt(props?.trajet?.duration?.split("h")[1]) || 0;

    // Calculate return time
    const totalMinutes = minutes + durationMinutes;
    const additionalHours = Math.floor(totalMinutes / 60);
    const finalMinutes = totalMinutes % 60;
    const finalHours = hours + durationHours + additionalHours;

    // Set formatted time and date
    setTimeDept(`${hours.toString().padStart(2, '0')}h${minutes.toString().padStart(2, '0')}`);
    setTimeRetour(`${finalHours.toString().padStart(2, '0')}h${finalMinutes.toString().padStart(2, '0')}`);
    setFormattedDate(`${dayName} ${day.toString().padStart(2, '0')} ${monthName}`);
};


  return (
    <>
      <div className="bloc-trajet ">
        <div className="d-flex justify-content-between align-items-center">
          <div className="subtitle bleuM500">{props.trajetType==="retour"? `Retour : ${formattedDate}`:`Aller : ${formattedDate}`}</div>
          <div className="">
            {props.from === "myTargets" ? (
              <p className="legend bleuM500 mb-0">{props?.trajet?.distance}</p>
            ) : (
              <p className="legend bleuM500 mb-0">{props?.trajet?.nbrPlace} places</p>
            )}
          </div>
        </div>
        <div className="trip-details mt-16">
          <div className="trip-time">
            <span className="exergue mb-20"> {timeDept}</span>
            <span className="legend bleu400 mb-20">{props?.trajet?.duration}</span>
            <span className="exergue">{timeRetour}</span>
          </div>
          <div className="ml-8 trip-address">
            {props.trajetType==="retour" 
            ?<>
              <p className="legend mb-0 bleuM500">{props?.trajet?.adresseFinal}</p>
            <p className="legend mb-0 bleuM500">{props?.trajet?.adresseDepart}</p>
            </>
          :
          <><p className="legend mb-0 bleuM500">{props?.trajet?.adresseDepart}</p>
            <p className="legend mb-0 bleuM500">{props?.trajet?.adresseFinal}</p></>}
           
          </div>
          
        </div>
        {(props?.trajet?.isFlexibleDep && !props.retour && props.trajetType!=="retour" ) ? <div className="isFlexible mt-16">
            Le passager est flexible dans ses disponibilités  {props?.trajet?.horaires[0]?.selectedDayDept} {props?.trajet?.horaires[0]?.selectedPeriodsDept}. Veuillez le contacter pour en discuter.
            </div>
         :''}
          {props?.trajet?.isFlexibleDest && props.trajetType==="retour"  ? <div className="isFlexible mt-16">
                  Le passager est flexible dans ses disponibilités {props?.trajet?.horaires[0]?.selectedDayDest} {props?.trajet?.horaires[0]?.selectedPeriodsDest}. Veuillez le contacter pour en discuter.
                  </div>
          :''}
         
      </div>
      
    </>
  );
};

export default CardTrajet;
