import React, { useState,useEffect } from 'react';

// Import des icônes
import HomeIconActive from '../assets/images/house-active.svg';
import HomeIcon from '../assets/images/home.svg';
import TrajetsIconActive from '../assets/images/trip-active.svg';
import TrajetsIcon from '../assets/images/trip-destination.svg';
import AmisIconActive from '../assets/images/users-actives.svg';
import AmisIcon from '../assets/images/users.svg';
import MessagesIconActive from '../assets/images/messages-bubble-typing-active.svg';
import MessagesIcon from '../assets/images/messages.svg';
import ProfilIconActive from '../assets/images/profil-active.svg';
import ProfilIcon from '../assets/images/profil.svg';
import { useLocation } from 'react-router-dom';
import Services from '../services/global.service';

const TopBar = ({header}) => {
  const location = useLocation(); // Utilisation de useLocation pour obtenir l'URL actuelle
const [msgNotSeen,setMsgNotSeen]=useState(0)
  // Fonction pour ajouter la classe active en fonction de l'URL
  const getActiveClass = (path) => {
    return location.pathname === path ||(location.pathname.startsWith(path) && path!=="/")   ? 'active' : '';
  };
  const [user,setUser]=useState()
  const [allTrips, setAllTrips] = useState(0);

  const getMyTargets = () => {
    Services.getAll("filteredtrajets")
      .then((res) => {
        // console.log('demandes', res?.data)
        const countOnHoldRequests = res?.data.reduce((count, item) => {
          if (item.trajet && item.trajet.conducteurtrajet?.id ==localStorage.getItem('userData')) {
              count += item.trajet.trajet_reservation.filter(reservation => reservation.status === "onHold").length;
          }
          return count;
      }, 0);
      
      // console.log({countOnHoldRequests});
        setAllTrips(countOnHoldRequests);
      })
      .catch((err) => {
        // console.log(err);
      });
  };
  const getMyProfil=()=>{
    const idUser=localStorage.getItem('userData')
    // console.log('userData',idUser)
    Services.getOne(idUser,'users')
    .then((res)=>{
      // console.log(res)
      setUser(res?.data?.data)
      
    })
    .catch((err)=>{
      // console.log(err)
    })
  }
  useEffect(()=>{
    getMyProfil()
    getMyTargets()
  },[])
  // Fonction pour obtenir l'icône en fonction de l'URL
  const getIcon = (path, icon, iconActive) => {
    return  location.pathname === path ||(location.pathname.startsWith(path) && path!=="/") ? iconActive : icon;
  };
  const getList = () => {
    Services.getAll("messages/showDiscussion")
      .then((res) => {
       // console.log("Response data:", res.data);
        // Verify if res.data is an array, otherwise adjust accordingly
        const totalMsgNotSeen = Array.isArray(res?.data)
          ? res?.data?.reduce((total, item) => total + (item?.messageNonLu || 0), 0)
          : 0;
            //console.log({totalMsgNotSeen})
          setMsgNotSeen(totalMsgNotSeen);
     
      })
      .catch((err) => {
        // console.log(err);
      });
  };
   useEffect(() => {
    getList()
  }, [])
  
  return (
  <>
 
    <div className={`${header ? 'top-bar-desktop w-50 mx-auto': 'top-bar' }`}>
      <a href="/" className={`menu-item ${getActiveClass('/')} ${getActiveClass('/search')} ${getActiveClass('/search-solidaire')}`}>
        <img src={getIcon('/', HomeIcon, HomeIconActive)} alt="Accueil" className="menu-icon" />
        <span>Accueil</span>
      </a>
      <a href="/mes-trajets" className={`menu-item ${getActiveClass('/mes-trajets')}`}>
      <div className="icon-container">
         <img src={getIcon('/mes-trajets', TrajetsIcon, TrajetsIconActive)} alt="Mes trajets" className="menu-icon" />
        {allTrips>0 && <div className="notSeen">{allTrips}</div>}  
        </div> <span>Mes trajets</span>
      </a>
      <a href="/amis-groupes" className={`menu-item ${getActiveClass('/amis')} ${getActiveClass('/group')}`}>
        <img src={getIcon('/amis-groupes', AmisIcon, AmisIconActive)} alt="Mes amis" className="menu-icon" />
        <span>Mes amis</span>
      </a>
      <a href="/mes-messages" className={`menu-item ${getActiveClass('/mes-messages')}`}>
      <div className="icon-container">
   
        <img src={getIcon('/mes-messages', MessagesIcon, MessagesIconActive)} alt="Messages" className="menu-icon" />
        {msgNotSeen>0 && <div className="notSeen">{msgNotSeen}</div>}  
        </div><span>Messages</span>
      </a>
      <a href="/mon-profil" className={`menu-item ${getActiveClass('/mon-profil')}`}>
        <img src={getIcon('/mon-profil', ProfilIcon, ProfilIconActive)} alt="Profil" className="menu-icon" />
        <span>Profil</span>
      </a>
    </div>
    </>
  );
};

export default TopBar;
