import { Helmet, HelmetProvider } from "react-helmet-async";
import Layout from "./layout";
import { useNavigate } from "react-router-dom";
import ReturnIcon from "../assets/images/return.svg";

const Pc = () => {
  const navigate = useNavigate();
  return (
    <Layout>
      <HelmetProvider>
        <Helmet>
          <title>Politique de confidentialité </title>
          <meta
            name="description"
            content="page Politique de confidentialité"
          />
        </Helmet>
      </HelmetProvider>
      {/* <div className="bloc-search min-h-200">
        <div className="container-fluid-lg">
          <h1 className="text-white p-16 d-flex">
            <button onClick={() => navigate(-1)} className="cta">
              <img src={ReturnIcon} className="mr-8" alt="return icon" />
            </button>
            Politique de confidentialité
          </h1>
        </div>
        </div> */}
        <div className="p-16 col-lg-6 mx-auto">
          <h1 className="bleuM500 mt-24"> Politique de confidentialité</h1>
          <p className="exergue  mt-24">Introduction</p>
          <p className="current-text mt-8">
            La confidentialité des visiteurs de notre site web et des membres de
            la communauté ATCHOUM est très importante à nos yeux, et nous nous
            engageons à la protéger. Cette politique détaille ce que nous
            faisons de vos informations personnelles et comment vous pouvez
            exercer vos droits. Si vous avez des questions sur les conditions
            d’utilisation de vos données personnelles, si vous souhaitez exercer
            vos droits contactez ATCHOUM à atchoum@lamobilitedeproximite.fr
          </p>
          <p className="exergue  mt-24">
            1. Quels types de données personnelles ATCHOUM collecte-t-elle et
            pour quelles raisons ?
          </p>
          <p className="current-text mt-8">
            ATCHOUM collecte et utilise vos données personnelles pour gérer les
            activités de co-voiturage. Il s’agit de traitements permettant la
            gestion de vos profils sur notre plateforme, la mise en relation
            conducteur/passager, la réservation de trajets, la gestion des
            données administratives nécessaires aux opérations de facturation et
            de statistiques. Les données utilisées sont :<br />
            • des données d’identification, telles que les noms, prénoms,
            adresses, emails, téléphones et photos…
            <br />
            • des données liés à la conduite, telle que le permis de conduire,
            la carte grise, assurance auto,
            <br />
            • des informations bancaires, telle que votre RIB pour vous
            rémunérer
            <br />
            • des données de connexions lorsque vous acceptez les cookies
            <br />
            La base juridique des traitements repose sur votre consentement.
            Vous nous l’accordez lorsque vous vous cochez la case consentement
            lors de votre inscription à notre plateforme. Votre consentement est
            facile à retirer, il vous suffit de supprimer votre profil. Les
            cookies :<br />
            Nous respectons vos choix concernant l’utilisation de cookies,
            lorsque vous autorisez leur utilisation ils nous permettent de vous
            proposer une navigation optimale et de mesurer l’audience du site.
            Vous disposez sur le site d’un mécanisme permettant de contrôler les
            cookies. Par défaut, sans votre autorisation, aucun cookies
            d’identification personnelle n’est réalisée.
          </p>
          <p className="exergue  mt-24">
            2. Qui sont les destinataires de vos données et combien de temps les
            gardons-nous ?
          </p>
          <p className="current-text mt-8">
            Les destinataires de vos données sont les services d’ATCHOUM. Vos
            données ne font l’objet d’aucun échange avec des organisations
            externes, elles ne sont pas transférées en dehors de l’union
            européenne. Vos données sont conservées le temps nécessaire à nos
            finalités : par exemple le temps de votre inscription à nos services
            + 1 an pour ce qui concerne les données collectées via notre service
            de co-voiturage, 5 ans pour les données liées à la facturation, 13
            mois pour les cookies.
          </p>
          <p className="exergue mt-24">
            3. Comment ATCHOUM protège vos données ?
          </p>
          <p className="current-text mt-8">
            Les informations personnelles qui nous sont fournies par le biais de
            notre site web seront utilisées dans les objectifs décrits dans
            cette politique ou dans les pages du site pertinentes. Nous pouvons
            utiliser vos informations personnelles pour: ATCHOUM est engagée
            dans une démarche de qualité en général et de sécurité en
            particulier. ATCHOUM utilise les services d’un cabinet certifié en
            protection de données (Bureau Véritas/CNIL et ISO 27001) ; cela nous
            permet de définir et mettre en œuvre les moyens organisationnels,
            techniques et juridiques adaptés contre les violations de
            confidentialité, d’intégrité et de disponibilité des données. Notre
            système d’information est sécurisé et résilient. Nos partenaires
            sont maitrisés. Nos pratiques conformes à l’état de l’art. Notre
            personnel suit un programme de sensibilisation.
          </p>
          <p className="exergue mt-24">
            4. Quels sont vos droits et comment les exercer ?
          </p>
          <p className="current-text mt-8">
            Droit d’accès Vous pouvez nous demander la confirmation que des
            données à caractère personnel vous concernant sont ou non traitées
            par nos soins. Vous avez par ailleurs un droit d’accès aux données
            personnelles que nous détenons vous concernant. Vous pouvez formuler
            une demande de copie de ces informations, par écrit et accompagnée
            d’une copie d’un titre d’identité signé et à jour. Droit de
            rectification Vous disposez d’un droit de rectification de données
            inexactes ou incomplètes. Il vous suffit pour cela de nous faire
            parvenir par écrit une déclaration complémentaire. Droit à
            l’effacement Vous avez le droit de demander l’effacement de vos
            données à caractère personnel dans les cas suivants :<br />
            • les données ne sont plus nécessaires eu égard aux finalités pour
            lesquelles nous avons initialement effectué un traitement ;<br />
            • vous avez retiré votre consentement pour le traitement de vos
            données, et il n’y a pas d’autre fondement juridique au traitement ;
            <br />
            • les données ont fait l’objet d’un traitement illicite;
            <br />
            • vous vous êtes opposé au traitement nécessaire aux fins des
            intérêts légitimes poursuivis par ATCHOUM et il n’existe pas de
            motif légitime impérieux. Droit à la limitation du traitement
            <br />
            Vous avez le droit d’obtenir la limitation du traitement de vos
            données. Vos données ne pourront dans ce cas faire l’objet d’aucun
            traitement par nos soins sans un consentement express de votre part,
            à l’exception de leur conservation. Vous avez la possibilité
            d’exercer ce droit dans les circonstances suivantes :<br />
            • vous contestez l’exactitude des données à caractère personnel que
            nous traitons vous concernant (le temps de nous permettre de
            procéder aux vérifications nécessaires);
            <br />
            • le traitement est illicite mais vous demandez à ce qu’il soit
            limité et non supprimé ;<br />
            • vous vous opposez au traitement de vos données à caractère
            personnel aux fins des intérêts légitimes poursuivis par ATCHOUM ;
            <br />
            • nous n’avons plus besoin de traiter vos données à caractère
            personnel mais ces données sont nécessaires à la constatation,
            l’exercice ou la défense d’un droit en justice. Droit à la
            portabilité des données
            <br />
            Vous avez le droit de récupérer les données que vous avez fournies,
            dans un format structuré, couramment utilisé et lisible par une
            machine. Il doit s’agir de données traitées après recueil de votre
            consentement ou en raison de l’exécution d’un contrat vous liant à
            ATCHOUM. Droit d’opposition Vous pouvez vous opposer, pour des
            raisons tenant à votre situation particulière, à un traitement de
            données à caractère personnel vous concernant dès lors qu’il a pour
            base légale notre intérêt légitime. Droit de retirer votre
            consentement Lorsque ATCHOUM a obtenu votre consentement pour
            traiter vos données à caractère personnel pour une ou plusieurs
            finalités, vous pouvez retirer ce consentement à tout moment sauf
            s’il existe une autre base légale à ce traitement. Droit
            d’introduire une réclamation auprès de la CNIL Si vous estimez,
            après nous avoir contactés, que vos droits Informatique et Libertés
            ne sont pas respectés ou que les traitements et dispositifs ne sont
            pas conformes aux règles de protection des données, vous pouvez
            adresser une réclamation en ligne à la CNIL ou par courrier postal.
            Pour exercer vos droits contactez notre référent RGPD à
            atchoum@lamobilitedeproximite.fr
          </p>
          <p className="exergue mt-24">
            5. Modification de la politique de confidentialité
          </p>
          <p className="current-text mt-8">
            ATCHOUM se réserve le droit de faire évoluer la présente Politique
            de protection des données personnelles à tout moment, notamment en
            application des changements apportés aux lois et réglementations en
            vigueur.
          </p>{" "}
        </div>
      {/* </div> */}
    </Layout>
  );
};
export default Pc;
