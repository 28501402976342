import React, { useMemo, useState } from "react";
import ReturnIcon from "../../assets/images/return.svg";
import { useLocation, useNavigate } from "react-router-dom";
import "../../assets/styles/demandeTrajet.scss";
import BtnPlus from "../../assets/images/btnplus.svg";
import { FormCheck } from "react-bootstrap";
import TrashIcon from "../../assets/images/trashbtn.svg";
import Alert from "../../assets/images/danger.svg";
import Services from "../../services/global.service";
import { HelmetProvider ,Helmet} from "react-helmet-async";
import { toast } from "react-toastify";

const Tickets = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const trajet = location?.state?.trajet;
  const inputs = location?.state?.inputs;
  const idConducteur = location?.state?.conducteur_id;
  //const allerRetour=trajet?.id ? 1 : (trajet?.searchData?.input_data?.isFlexibleDest || trajet?.searchData?.input_data?.dateArrive)? 2 : 1
  //  console.log({allerRetour})
  const nb =
    Math.ceil(
      (parseFloat(trajet?.prixTrajet) *
        parseInt(
          !trajet?.id
          ? 1
          : trajet?.searchData?.input_data?.nbrPlace || inputs?.nbrPlace || 1
        )) /
        1.25
    ) || 1; // Total number of tickets available
  const carnetSize = 10; // Number of tickets in a carnet
  const [tickets, setTickets] = useState(Array(nb).fill("")); // Array to store ticket inputs
  const [visibleTickets, setVisibleTickets] = useState(1); // Initial count of visible ticket inputs
  const [carnets, setCarnets] = useState([{ carnet: false, firstTicket: "" }]); // Store each carnet's status and first ticket
  const [ticketErrors, setTicketErrors] = useState({});
  const carnetTrueCount = carnets?.filter((c) => c?.carnet === true)?.length; // Number of full carnets selected

  // Handle input change for tickets
  const handleTicketChange = (index, value) => {
    const newTickets = [...tickets];
    newTickets[index] = value;
    setTickets(newTickets);

    // Clear the error for the specific ticket
    const newTicketErrors = { ...ticketErrors };
    newTicketErrors[index] = ""; // Clear the error
    setTicketErrors(newTicketErrors);
  };

  // Handle change for carnet's first ticket input
  const handleFirstTicketChange = (index, value) => {
    const updatedCarnets = [...carnets];
    updatedCarnets[index].firstTicket = value; // Store the first ticket for this carnet
    setCarnets(updatedCarnets);
  };

  // Handle toggle change for each carnet
  const handleCarnetChange = (index) => {
    const updatedCarnets = [...carnets];
    const currentCarnetStatus = updatedCarnets[index]?.carnet;

    // Toggle the carnet status
    updatedCarnets[index].carnet = !currentCarnetStatus;
    setCarnets(updatedCarnets);

    const totalTicketsUsed = carnetTrueCount * carnetSize + visibleTickets;

    // Add a new carnet if there are enough tickets left for a full carnet
    if (!currentCarnetStatus && totalTicketsUsed + carnetSize <= nb) {
      setCarnets([...updatedCarnets, { carnet: false, firstTicket: "" }]);
    }

    // If carnet is checked, reset the corresponding ticket input
    if (!currentCarnetStatus) {
      handleTicketChange(index, ""); // Reset ticket input for this carnet
    }
  };

  // Add a new carnet or individual tickets
  const handleAddCarnet = () => {
    const totalTicketsUsed =
      carnetTrueCount * carnetSize + visibleTickets - carnetTrueCount;

    // Add a new carnet if there are enough tickets left for a full carnet
    if (totalTicketsUsed + carnetSize <= nb) {
      setVisibleTickets(visibleTickets + 1);
    }
    // Otherwise, add individual tickets
    else if (totalTicketsUsed < nb) {
      setVisibleTickets(visibleTickets + 1);
    }
  };

  // Remove a carnet
  const handleRemoveCarnet = (index) => {
    const updatedCarnets = carnets.filter((_, i) => i !== index);
    setCarnets(updatedCarnets);
  };
  const handleRemoveTicket = (index) => {
    if (!carnets[index]?.carnet) {
      // Only proceed if it's an individual ticket
      const updatedTickets = tickets.filter((_, i) => i !== index);
      setTickets(updatedTickets);
      setVisibleTickets(visibleTickets - 1); // Adjust the visible ticket count
    }
  };
  // Calculate the remaining tickets to add (à verifier)
  const getRemainingTickets = () => {
    const carnetTrue = carnetTrueCount || 0;
    const carnetSizeValue = carnetSize || 0;
    const totalCarnetTickets = carnetTrue * carnetSizeValue;
  
    const totalIndividualTickets = (tickets || [])
      .slice(0, visibleTickets)
      .filter(Boolean).length;
  
    return (nb || 0) - totalCarnetTickets - totalIndividualTickets;
  };
  const remainingTickets = useMemo(() => getRemainingTickets(), [
    carnetTrueCount,
    carnetSize,
    tickets,
    visibleTickets,
    nb
  ]);
    

  const isTicketValid = (ticket) => {
    const regex = /^[0-9]{7}$/; // 7 digits only
    return regex.test(ticket); // Returns true if the ticket matches 7 digits
  };
  const isTicketInCarnet = (ticketNumber) => {
    // Check if ticketNumber is within the range of any carnet
    return carnets.some((carnet) => {
      if (carnet.carnet && carnet.firstTicket) {
        const firstTicketNumber = parseInt(carnet.firstTicket, 10);
        const ticketToCheck = parseInt(ticketNumber, 10);
        const lastTicketNumber = firstTicketNumber + carnetSize - 1;
        return ticketToCheck >= firstTicketNumber && ticketToCheck <= lastTicketNumber;
      }
      return false;
    });
  };
  const isTicketDuplicate = (ticketNumber) => {
    if (!ticketNumber) {
      return false; // No ticket number to check
    }
  // console.log(ticketNumber)
    // Ensure all tickets are trimmed and valid numbers
    const validTickets = tickets.filter((ticket) => ticket.trim() !== "");
    const parsedTicketNumber = ticketNumber.toString();
  // console.log(validTickets)
    // Check if the ticket number exists in the array
    const occurrences = validTickets.filter((ticket) => ticket === parsedTicketNumber).length;
    // console.log(occurrences)
    // Allow the first occurrence, return true only for duplicates
    return occurrences > 1;
  };
  const checkTicket = (type, value, index) => {
    // console.log('hereee',{type, value, index})
    const ticketNumber = parseInt(value, 10);

    // Check if ticket is within any carnet range
    if (isTicketInCarnet(ticketNumber) && type === "ticket") {
      const errorMessage =
        "Ce numéro de ticket appartient déjà à un carnet existant.";
      setTicketErrors((prev) => ({ ...prev, [index]: errorMessage }));
      return;
    }
    if (isTicketDuplicate(ticketNumber) && type === "ticket") {
      const errorMessage =
        "Ce numéro de ticket a déjà été ajouté ";
      setTicketErrors((prev) => ({ ...prev, [index]: errorMessage }));
      return;
    }
    if (!isTicketValid(value)) {
      // Handle invalid format
      const errorMessage =
        "Le numéro de ticket doit contenir exactement 7 chiffres.";
      setTicketErrors((prev) => ({ ...prev, [index]: errorMessage }));
      return; // Do not send the request if the format is invalid
    }

    // Clear any previous error for this ticket
    setTicketErrors((prev) => ({ ...prev, [index]: null }));
    const data = {
      numticket: value,
      carnet: type === "carnet",
    };
    Services.create(data, "verifyTicket")
      .then((res) => {
        // if ticket consomé ou not existe in database (file imported by admin)
        if (!res.data.success) {
          setTicketErrors((prev) => ({ ...prev, [index]: res.data.message }));
        } else {
          setTicketErrors((prev) => ({ ...prev, [index]: "" }));
        }
        // console.log('res check ticket',res)
      })
      .catch((err) => {
        // console.log(err);
      });
  };
  const getNbrPlace = () => {
    if (!trajet?.id) {
      return 1; // Return 1 if there is no trajet.id
    }
    return trajet?.searchData?.input_data?.nbrPlace || inputs?.nbrPlace || 1;
  };
  // Handle form submission
  const handleSubmit = () => {
    try {
      // Filter carnet and ticket data
      const carnetFiltred = carnets?.filter((c) => c?.carnet === true);
      const filtredTickets = tickets?.filter((c) => c !== "");

      // Construct `dataTrajet` object with default values if properties are missing
      const dataTrajet = {
        typeTrajet: trajet?.id ? 1 : 0, // Trajet solidaire
        adrDepartAltitude:
          trajet?.searchData?.input_data?.adrDepartAltitude || "",
        adrDepartLongitude:
          trajet?.searchData?.input_data?.adrDepartLongitude || "",
        adresseDepart: trajet?.searchData?.input_data?.adresseDepart || "",
        communes: trajet?.searchData?.input_data?.communes
          ? JSON.stringify(trajet.searchData.input_data.communes)
          : "",
        adrFinalAltitude:
          trajet?.searchData?.input_data?.adresseDesAltitude || "",
        adresseFinal: trajet?.searchData?.input_data?.adresseDestination || "",
        adrFinalLongitude:
          trajet?.searchData?.input_data?.adresseDesLongitude || "",
        dateRetour: new Date(trajet?.searchData?.input_data?.dateArrive),
        dateDepart: new Date(trajet?.searchData?.input_data?.dateDepart),
        nbrPlace: trajet?.searchData?.input_data?.nbrPlace,
        codePostalDepart:trajet?.searchData?.input_data?.postalCode?.dept,
        codePostalArrivee:trajet?.searchData?.input_data?.postalCode?.dest,
        conducteur: idConducteur || null,
        prixTrajet: parseFloat(trajet?.prixTrajet) * parseInt(getNbrPlace()),
        // prixTrajet:
        //   parseFloat(trajet?.prixTrajet) *
        //   parseInt(trajet?.searchData?.input_data?.nbrPlace) ,
        isFlexibleDest: trajet?.searchData?.input_data?.isFlexibleDest || false,
        selectedDayDest: trajet?.searchData?.input_data?.selectedDayDest || "",
        selectedPeriodsDest:
          trajet?.searchData?.input_data?.selectedPeriodsDest || "",
        isFlexibleDep: trajet?.searchData?.input_data?.isFlexibleDep || false,
        selectedDayDept: trajet?.searchData?.input_data?.selectedDayDept || "",
        selectedPeriodsDept:
          trajet?.searchData?.input_data?.selectedPeriodsDept || "",
        commentaireTrajet: trajet?.searchData?.input_data?.commentaire || "",
      };

      //  console.log("Form data:", dataTrajet);

      // Payment submission data
      const submitPayment = (trajetId) => {
        const paymentData = {
          carnetFiltred,
          trajet_id: trajetId,
          dateReservation: trajet?.searchData?.input_data?.dateDepart || trajet?.dateDepart,
          tickets: filtredTickets,
          price:
            parseFloat(trajet?.prixTrajet) *
            parseInt(getNbrPlace()) ,
          id_conducteur: idConducteur || null,
          nbrplace:
            trajet?.searchData?.input_data?.nbrPlace || inputs?.nbrPlace || 1,
        };
         //console.log({paymentData})
        // Submit payment data
        Services.create(paymentData, "paiementTrajetWithTicket")
          .then((res) => {
            // console.log("Payment response:", res);
            window.location.href = `/confirmation-demande`;
          })
          .catch((err) => {
              toast.error(err?.response?.data?.message, {
                      position: 'top-right',
                      autoClose: 3000,
                      hideProgressBar: true,
                      closeOnClick: true,
                      draggable: true,
                      progress: undefined,
                     // theme: 'light', // Choose theme (light/dark)
                      className: 'custom-toast-error'
              
                    });
            console.error("Error during payment:", err);
          });
      };

      // If there's a `trajet` ID, skip creation and go to payment
      if (trajet?.id) {
        submitPayment(trajet.id);
      } else {
        // console.log('im in not existing trajet')
        // Otherwise, create the trajet first, then proceed to payment
        Services.create(dataTrajet, "addTrajet")
          .then((res) => {
            // console.log("Trajet creation response:", res);
            submitPayment(res.data.trajet.id);
          })
          .catch((err) => {
            toast.error(err?.response?.data?.message, {
              position: 'top-right',
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              draggable: true,
              progress: undefined,
             // theme: 'light', // Choose theme (light/dark)
              className: 'custom-toast-error'
      
            });
            console.error("Error during trajet creation:", err);
          });
      }
    } catch (error) {
      console.error("Error in handleSubmit:", error);
    }
  };

  return (
    <div className="bg-grey">
      <HelmetProvider>
         <Helmet>
            <title>Page paiement par ticket </title>
            <meta name="description" content="page paiement par tickets" />
        </Helmet>
      </HelmetProvider>
      
      <div className="stepTickets">
        <div className="container-fluid-lg p-16">
          <h1 className="text-white d-flex align-items-center">
            <button onClick={() => navigate(-1)} className="cta">
              <img src={ReturnIcon} className="mr-8" alt="return icon" />
            </button>
            Demande de trajet
          </h1>
        </div>
      </div>

      <div className="p-16 col-lg-6 mx-auto">
        <h2 className="bleuM500">Ajoutez vos numéros de tickets mobilité</h2>

        {/* Individual Ticket Inputs */}
        {tickets?.slice(0, visibleTickets)?.map((ticket, index) => (
          <div key={index}>
            <div className="col">
              <div className="custom-switch custom-switch-label-io d-flex align-items-center">
                <FormCheck
                  type="switch"
                  id={`custom-switch-${index}`}
                  className="form-switch mr-8"
                  checked={carnets[index]?.carnet || false} // Use the carnet status directly
                  onChange={() => handleCarnetChange(index)} // Only call this function
                  disabled={getRemainingTickets() < 10 && !carnets[index]?.carnet}
                />
                <label
                  className="bleuM500 exergue"
                  htmlFor={`custom-switch-${index}`}
                >
                  J’ai un carnet complet (10 tickets)
                </label>
              </div>
            </div>

            <div className="floating-input-container mb-3 mt-8">
              {carnets[index]?.carnet && (
                <p className="current-text mt-16 mb-0">
                  Renseignez le numéro du 1er ticket de votre carnet.
                </p>
              )}
              <div className="d-flex justify-content-center">
                <input
                  type="text"
                  maxLength="7"
                  name={
                    carnets[index]?.carnet
                      ? `carnet-${index + 1}`
                      : `ticket-${carnetTrueCount * carnetSize + index + 1}`
                  }
                  value={
                    carnets[index]?.carnet
                      ? carnets[index]?.firstTicket
                      : ticket
                  }
                  onChange={(e) => {
                    if (carnets[index]?.carnet) {
                      handleFirstTicketChange(index, e.target.value); // Handle first ticket input changes
                    } else {
                      handleTicketChange(index, e.target.value); // Handle ticket input changes
                    }
                  }}
                  onBlur={(e) => {
                    if (carnets[index]?.carnet) {
                      checkTicket("carnet", e.target.value, index); // Check carnet ticket validity on blur
                    } else {
                      checkTicket("ticket", e.target.value, index); // Check ticket validity on blur
                    }
                  }}
                  className="floating-input p-16 mt-8"
                  placeholder={
                    carnets[index]?.carnet
                      ? "Numéro de ticket"
                      : `Ticket mobilité n° ${
                          carnetTrueCount * carnetSize +
                          index +
                          1 -
                          carnetTrueCount
                        }`
                  }
                  required
                />
                {/* Trash Icon to remove carnet */}

                {!carnets[index]?.carnet && (
                  <button
                    onClick={() => handleRemoveTicket(index)}
                    className="cta"
                  >
                    <img src={TrashIcon} alt="delete carnet" />
                  </button>
                )}
              </div>

              {ticketErrors[index] && (
                <div className="error-message d-flex align-items-center mt-8">
                  <img src={Alert} alt="error" className="mr-2" />
                  <span>{ticketErrors[index]}</span>
                </div>
              )}
            </div>
          </div>
        ))}
        {getRemainingTickets() > 0 && (
          <div className="text-start">
            <button className="cta" onClick={handleAddCarnet}>
              <img src={BtnPlus} alt="Add" />
            </button>
          </div>
        )}
        <div className="h-xs-100 mb-5"></div>

        {/* Bottom Section */}
        <div className="bottom-white-block mt-4">
          <div className="text-center d-flex justify-content-between col-lg-6 mx-auto">
            <p className="current-text bleuM500 mb-0">Reste à ajouter</p>
            <p className="current-text bleuM500">
              {remainingTickets} tickets
            </p>
          </div>

          <div className="text-center mt-4">
            <button
              type="button"
              onClick={handleSubmit}
              className="btn-primary-s p-16 w-sm-100 mt-2"
              disabled={getRemainingTickets() > 0}
            >
              Payer
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tickets;
